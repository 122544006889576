::-webkit-scrollbar {
    width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #000000;
}

/*     Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #373737;
}

/* @font-face {
    font-family: "MyFont";
    src: url("src/shared/img/logo/font/LynkcoType-Regular.ttf") format("truetype");
}

@font-face {
    font-family: "MyFontMedium";
    src: url("src/shared/img/logo/font/LynkcoType-Medium.ttf") format("truetype");
} */


#containerbody {
    padding-left: 40px;
    padding-right: 0px;
}

#TabletitlesPoolCars {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    position: relative;
}

#Tabletitles {
    display: inline;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
}

.cardcolor {
    background-color: #000000;
}

.horizontal-line {
    border-bottom: 3px solid #808080;
}

/*images styling*/

.photo1 {
    height: 57px;
    width: 58px;
    margin-right: 5px;
}

.photo2 {
    height: 50px;
    width: 60px;
}

.photo3 {
    height: 50px;
    width: 8px;
    margin-right: 5px;
}

.photo4 {
    height: 49px;
    width: 40px;
    margin-right: -11px;
}

.photo {
    height: 30px;
    width: 42px;
}

.image-container:hover .tableviewSquare {
    filter: brightness(50%);
}

.tableviewSquare {
    height: 20px;
    width: 20px;
    margin-right: 10px;
    float: right;
    cursor: pointer;
    transition: filter 0.3s ease;
}

.tableviewlist {
    height: 20px;
    width: 20px;
    float: right;
    cursor: pointer;
}

/*table*/

#tablecard {
    background-color: #000000;
    width: 100%
}

#customers {
    /* font-family: "MyFont"; */
    border-spacing: 0;
    width: 100%;
    color: white;
    border-collapse: collapse;
    /* make the table borders collapse to each other */
    width: 100%;
}

#customers th {
    background: #000000;
    position: sticky;
    z-index: 100;
}

#customers th:nth-child(2) {
    /* padding-right: 20px; */
}


#customers td {
    /* border-bottom: 5px solid #000000; */
    padding-left: 20px;
    white-space: nowrap;
}

#customers td:nth-child(1) {
    /* border-bottom-left-radius: 20px; */
    /* background: red; */
    padding-right: 500px;

    /* border-bottom: 5px solid #000000; */
}

#customers td:nth-child(2) {
    /* border-bottom-left-radius: 20px; */
    /* background: red; */
    padding-right: 500px;

    /* border-bottom: 5px solid #000000; */
}

#customers tr {
    /* background-image: linear-gradient(97deg, rgba(10, 10, 10, 1) 0%, rgba(37, 37, 37, 1) 99%); */
    align-items: center;
    padding-left: 20px;
}



#customers th {
    padding-top: 12px;
    padding-bottom: 12px;
    color: white;
    padding-left: 20px;
}


#admin {
    /* font-family: "MyFont"; */
    border-spacing: 0;
    width: 100%;
    color: white;
    border-collapse: collapse;
    /* make the table borders collapse to each other */
    width: 100%;
}

#admin th {
    background: #000000;
    font-size: 13px !important;
    position: sticky;
    z-index: 100;
}

#admin th:nth-child(2) {
    padding-right: 20px;
}


#admin td {
    /* border-bottom: 5px solid #000000; */
    padding-left: 20px;
    white-space: nowrap;
}

#admin td:nth-child(1) {
    /* border-bottom-left-radius: 20px; */
    /* background: red; */
    /* padding-right: 15px; */
    max-width: 250px;
    min-width: 180px;

    /* border-bottom: 5px solid #000000; */
}

#admin td:nth-child(2) {
    /* border-bottom-left-radius: 20px; */
    /* background: red; */
    /* padding-right: 15px; */
    max-width: 200px;
    min-width: 150px;

    /* border-bottom: 5px solid #000000; */
}

#admin td:nth-child(3) {
    /* border-bottom-left-radius: 20px; */
    /* background: red; */
    /* padding-right: 15px; */
    max-width: 160px;
    min-width: 100px;

    /* border-bottom: 5px solid #000000; */
}

#admin td:nth-child(4) {
    /* border-bottom-left-radius: 20px; */
    /* background: red; */
    /* padding-right: 15px; */
    max-width: 320px;
    min-width: 250px;

    /* border-bottom: 5px solid #000000; */
}

#admin tr {
    /* background-image: linear-gradient(97deg, rgba(10, 10, 10, 1) 0%, rgba(37, 37, 37, 1) 99%); */
    align-items: center;
    padding-left: 20px;
    font-family: 'LynkcoTypeLight';
    font-size: 15px !important;
    border-color: transparent;
}


#admin th {
    padding-top: 12px;
    padding-bottom: 1px !important;
    color: white;
    padding-left: 10px;
    background: transparent !important;
}


.fixed_header {
    border-collapse: collapse;
    border-spacing: 0;
    width: 100%;
    /* border: 1px solid #ddd; */
}

.fixed_header th,
.fixed_header td {
    text-align: left;
    padding: 8px;
}




/*cardbody-cardallowance*/

#CardBodyCardAllowance {
    background-image: linear-gradient(135deg, #0A0A0A, #373737 99%);
    border-radius: 4px;
    font-family: "MyFont";
    box-shadow: 3px 5px 6px #000000;
    height: 198px;
}

#CardBodyStyle {
    /* background-image: linear-gradient(135deg, #000000, #272727 99%); */
    background-color: transparent;
    border-radius: 15px;
    /* font-family: "MyFont"; */
    /* box-shadow: 1px 5px 1px #000000; */
    box-shadow: 3px 5px 6px #000000;
    height: 720px;
    margin-top: -5px;
}

#CardBodyCardAllowanceCreate {
    background-image: linear-gradient(135deg, #000000, #272727 99%);
    border-radius: 10px;
    /* font-family: "MyFont"; */
    /* box-shadow: 1px 5px 1px #000000; */
    /* box-shadow: 0px 3px 4px #1ef1c6; */
    border: 2px solid #333333;
    height: 250px;
    margin-top: -5px;
}

#CardBodyCardAllowanceVSP {
    background-image: linear-gradient(135deg, #101010 20%, #1B1B1B);
    border-radius: 4px;
    /* font-family: "MyFont"; */
    box-shadow: 3px 5px 6px #000000;
    height: 495px;
    margin-top: -5px;
}

#CardBodyCardAllowance3 {
    /* background-image: linear-gradient(135deg, #000000, #272727 99%); */
    /* background-color: transparent; */
    /* border-bottom-left-radius: 10px; */
    /* font-family: "MyFont"; */
    /* box-shadow: 3px 5px 6px #000000; */
    position: block;
    display: flex;
    border: 0;
    /* height: 720px;
    margin-top: -5px; */

}

#CardBodyCardCarousel {
    /* background-image: linear-gradient(135deg, #000000, #272727 99%); */
    background-color: transparent;
    /* border-bottom-left-radius: 10px; */
    /* font-family: "MyFont"; */
    box-shadow: 3px 5px 6px #000000;
    position: block;
    display: flex;
    /* height: 720px;
    margin-top: -5px; */

}

#ConnectedDriverCard {
    background-image: linear-gradient(135deg, #000000, #272727 99%);
    /* border-bottom-left-radius: 10px; */
    /* font-family: "MyFont"; */
    box-shadow: 3px 5px 6px #000000;
    position: block;
    display: flex;
    height: 744px;
    /* margin-top: -5px; */
    border-radius: 15px;
    margin-right: -5px;
    margin-left: 5px;

}

#CardBodyCardAllowance7 {
    /* background-image: linear-gradient(135deg, #101010 10%, #1B1B1B 60%); */
    background-image: linear-gradient(135deg, #101010, #1B1B1B 50%);
    /* border-bottom-left-radius: 10px; */
    /* font-family: "MyFont"; */
    box-shadow: 3px 5px 6px #000000;
    /* height: 720px;
    margin-top: -5px; */

}

#CardBodyCardAllowance8 {
    background-image: linear-gradient(135deg, #101010, #1B1B1B 50%);
    border-radius: 4px;
    /* font-family: "MyFont"; */
    box-shadow: 3px 5px 6px #000000;
    height: 720px;
    /* height: 720px;
    margin-top: -5px; */

}

#CardBodyCardAllowance1 {
    background-image: linear-gradient(135deg, #101010, #373737 99%);
    border-radius: 4px;
    font-family: "MyFont";
    box-shadow: 3px 5px 6px #000000;
    /* height: 130px; */
    height: 14vh;
}

#CardBodyCardAllowance4 {
    background-image: linear-gradient(135deg, #101010, #373737 99%);
    border-radius: 4px;
    font-family: "MyFont";
    box-shadow: 3px 5px 6px #000000;
    /* height: 450px; */
    height: 51.2vh;

}

#CardBodyCardAllowance5 {
    background-image: linear-gradient(135deg, #101010, #373737 99%);
    border-radius: 4px;
    font-family: "MyFont";
    box-shadow: 3px 5px 6px #000000;
    height: 200px;
}

#CardBodyCardAllowance6 {
    background-image: linear-gradient(135deg, #101010, #373737 99%);
    border-radius: 4px;
    font-family: "MyFont";
    box-shadow: 3px 5px 6px #000000;
}

.tableresponsive {
    width: 100%;
    overflow-y: auto;
}

#CardBody4layers {
    background-image: linear-gradient(135deg, #000000 20%, #1b1b1b 99%);
    border-radius: 4px;
    font-family: "MyFont";
    border-collapse: separate;
    border-spacing: 0;
    width: 100%;
    padding: 10px;
}


#CardBody4layers td {
    color: white;
    white-space: nowrap;
    /*word-break: break-all;*/
}




#CardBody4layers tr {
    align-items: center;
    color: white;
}

#CardBody4layers td:first-child {
    font-size: 55px;
    font-family: "MyFontMedium";
    color: #A9A9A9;
    padding-left: 5px;
}

#CardBody4layers td:nth-child(2) {
    font-size: 18px;
    padding-left: 20px;
    right: 20px;
    color: #A9A9A9;
}

#CardBody4layers td:last-child {
    /*float: right;*/
    /*  padding-top: 13px;*/
    text-align: right
}

/* #CardBody4layers tr:first-child {
        display: none;
    }*/
/*car allowance button*/
#carallowancebtn {
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 0px;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    background-color: transparent;
    width: 100%;
    border-color: #DC1860;
    color: #DC1860;
    text-align: left;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: -5px 2px;
    cursor: pointer;
    height: 50px;
    /* margin: 0; */
}

#employeecarbtn {
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 0px;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    background-color: transparent;
    width: 100%;
    border-color: Turquoise;
    color: Turquoise;
}

#ArrowRightIconstyle {
    float: right;
}

.table th,
.table td {
    padding: 1rem;
    vertical-align: middle;
}

.sidebarFontsize {
    font-size: calc(20px + 0.390625vw);
}

@media screen and (min-width: 100px) {
    .sidebarFontsize {
        font-size: calc(15px + 0.390625vw);
    }
}

@media screen and (min-width: 1276px) and (max-width: 1400px) {
    .sidebarFontsize {
        font-size: calc(20px + 0.390625vw);
        /* margin-left: 100px; */
    }
}

@media screen and (min-width: 1001px) and (max-width: 1275px) {
    .sidebarFontsize {
        font-size: calc(12px + 0.390625vw);
        /* margin-left: 100px; */
    }
}

@media screen and (min-width: 1401px) and (max-width: 1800px) {
    .sidebarFontsize {
        font-size: calc(18px + 0.390625vw);
        /* margin-left: 100px; */
    }
}

@media only screen and (min-width: 600px) and (max-width: 1000px) {
    .sidebarFontsize {
        font-size: calc(15px + 0.390625vw);
    }
}