/* @font-face {
    font-family: "LynkcoTypeRegular";
    src: url("src/shared/img/logo/font/LynkcoType-Regular.ttf") format("truetype");
}

@font-face {
    font-family: "LynkcoTypeMedium";
    src: url("src/shared/img/logo/font/LynkcoType-Medium.ttf") format("truetype");
}

@font-face {
    font-family: "LynkcoTypeBold";
    src: url("src/shared/img/logo/font/LynkcoType-Bold.ttf") format("truetype");
}

@font-face {
    font-family: "LynkcoTypeLight";
    src: url("src/shared/img/logo/font/LynkcoType-Light.ttf") format("truetype");
} */

.black-background {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(135deg, rgba(0, 0, 0, 1) 50%, rgba(34, 34, 34, 1) 99%);    
    display: block;
}

.column {
    width: 50%;
    height: 100%;
    background-color: aqua;
}

.centered-text {
    display: block;
    /* display:inline-flex;*/
    /*  justify-content: center;*/
    /*align-items: center;*/
    height: 100%;
    color: white;
    text-transform: uppercase;
    padding-top: 20%;
    padding-left:80px;
    /*position: fixed;*/
    /* top: 70%;
    left: 16%;*/
    /*  transform: translate(-50%, -50%);*/
    /*   margin:0, auto;
    padding-top:150px;*/
    /*text-align: center;*/
    /* padding-top:50%*/
}



.centered-text2 {
    /* display:inline-flex;*/
    display: block;
    /* display:inline-flex;*/
    /*  justify-content: center;*/
    /*align-items: center;*/
    height: 100%;
    color: white;
    text-transform: uppercase;
    padding-top: 17%;
    padding-left: 80px;
    /* padding-top:50%*/
}
/*.centered-div {

    margin: 0 auto;*/ /* set left and right margins to auto */
/*}*/
.textcaselower {
    text-transform: initial;
    font-size: 35px;
    font-family: "LynkcoTypeRegular";
 /*   text-align: left;*/
  /*  padding-top: 50px;*/
}

.centered-textparagraph {
    font-size: 110px;
    font-family: "LynkcoTypeBold";
    text-align: left;
}

@media (max-width: 768px) {
    .centered-text2 {
        /* display:inline-flex;*/
        display: block;
        /* display:inline-flex;*/
        /*  justify-content: center;*/
        /*align-items: center;*/
        height: 100%;
        color: white;
        text-transform: uppercase;
        padding-top: 0%;
        padding-left: 80px;
        /* padding-top:50%*/
    }
}

@media (min-width: 769px) and (max-width: 1414px) {
    .centered-text2 {
        /* display:inline-flex;*/
        display: block;
        /* display:inline-flex;*/
        /*  justify-content: center;*/
        /*align-items: center;*/
        height: 100%;
        color: white;
        text-transform: uppercase;
        padding-top: 0%;
        padding-left: 80px;
        /* padding-top:50%*/
    }
}

/*@media (min-width: 1025px) {
    .centered-textparagraph {
        font-size: 110px;
        font-family: "LynkcoTypeBold";
        text-align: left;
    }

   
}*/



.image-column {
    background-size: cover;
    background-position: center;
}

.text-left {
    text-align: left;
}

.text-border {
    padding-left: 80px;
}

.text-border-two {
    padding-left: 420px;
}

.text-border-three {
    padding-left: 67px;
}

.text-border-four {
    padding-left: 50px;
}



.slide-left {
    position: relative;
    animation: slideInAnimationleft 1s forwards;
   
}

@keyframes slideInAnimationleft {
    0% {
        left: -100%;
        opacity: 0;
    }

    100% {
        left: 0;
        opacity: 1;
    }
}

.slide-right {
    position: relative;
    animation: slideInAnimationright 1s forwards;
 
 
}

.new-line {
    display: block;
}

@keyframes slideInAnimationright {
    0% {
        right: -100%;
        opacity: 0;
    }

    100% {
        right: 0;
        opacity: 1;
    }
}

.slide-bottom {
    position: relative;
    animation: slideInAnimationbottom 1s forwards;
}

@keyframes slideInAnimationbottom {
    0% {
        bottom: -100%;
        opacity: 0;
    }

    100% {
        bottom: 0;
        opacity: 1;
    }
}

.containerbg {
    background-position: top right;
    background-repeat: no-repeat;
    /* Adjust the following properties as needed */
    background-size: 50%; /* Specify the size of the image */
    padding: 20px; /* Add padding to the container if necessary */
}
