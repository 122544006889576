.pac-container {
	background-color: #151515;
	margin-left: -10px;
	border: none;
	width: 238px !important;
}

.pac-item,
.pac-item-query {
	color: #f7f7f7;
	border: none;
	min-width: 500px;

}

.pac-item:hover {
	background: #6666;
}

.gm-style .gm-style-iw {
  border-radius: 10px;
  
}  

.gm-style-iw-d {
	overflow: hidden! important;
	padding-right: 20px;
	padding-bottom: 10px;
}
