::-webkit-scrollbar {}

/* Track */
::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #000000;
}

/*     Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #373737;
}

/* @font-face {
    font-family: "MyFont";
    src: url("src/shared/img/logo/font/LynkcoType-Regular.ttf") format("truetype");
}

@font-face {
    font-family: "MyFontMedium";
    src: url("src/shared/img/logo/font/LynkcoType-Medium.ttf") format("truetype");
} */


/* 
body {
    background-image: linear-gradient(180deg, rgba(0, 0, 0, 1) 50%, rgba(34, 34, 34, 1) 99%);
    font-family: "MyFont";
} */

#containerbody {
    padding-left: 40px;
    padding-right: 0px;
}

#TabletitlesPoolCars {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    position: relative;
}

#Tabletitles {
    display: inline;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
}

.cardcolor {
    background-color: #000000;
}

.horizontal-line {
    border-bottom: 3px solid #808080;
}

/*images styling*/

.photo1 {
    height: 57px;
    width: 58px;
    margin-right: 5px;
}

.photo2 {
    height: 50px;
    width: 60px;
}

.photo3 {
    height: 50px;
    width: 8px;
    margin-right: 5px;
}

.photo4 {
    height: 49px;
    width: 40px;
    margin-right: -11px;
}

.photo {
    height: 30px;
    width: 42px;
}

.image-container:hover .tableviewSquare {
    filter: brightness(50%);
}

.tableviewSquare {
    height: 20px;
    width: 20px;
    margin-right: 10px;
    float: right;
    cursor: pointer;
    transition: filter 0.3s ease;
}

.tableviewlist {
    height: 20px;
    width: 20px;
    float: right;
    cursor: pointer;
}

/*table*/

#tablecard {
    background-color: #000000;
    width: 100%
}



.tableFixHead {
    height: 550px;
    /* gives an initial height of 200px to the table */
    overflow: auto;
    /* make the table scrollable if height is more than 200 px  */
    overflow-x: hidden;
}

.tableFixHead thead th {
    position: sticky;
    /*make the table heads sticky */
    top: 0px;
    /*table head will be placed from the top of the table and sticks to it */
}

#customers {
    /* font-family: "MyFont"; */
    border-spacing: 0;
    width: 100%;
    color: white;
    border-collapse: collapse;
    /* make the table borders collapse to each other */
    width: 100%;
}

#customers th {
    background: #000000;
    position: sticky;
    z-index: 100;
}

#customers th:nth-child(2) {
    /* padding-right: 20px; */
}


#customers td {
    /* border-bottom: 5px solid #000000; */
    padding-left: 20px;
    white-space: nowrap;
}

#customers td:nth-child(1) {
    /* border-bottom-left-radius: 20px; */
    /* background: red; */
    padding-right: 500px;

    /* border-bottom: 5px solid #000000; */
}

#customers td:nth-child(2) {
    /* border-bottom-left-radius: 20px; */
    /* background: red; */
    padding-right: 500px;

    /* border-bottom: 5px solid #000000; */
}

#customers tr {
    /* background-image: linear-gradient(97deg, rgba(10, 10, 10, 1) 0%, rgba(37, 37, 37, 1) 99%); */
    align-items: center;
    padding-left: 20px;
}



#customers th {
    padding-top: 12px;
    padding-bottom: 12px;
    color: white;
    padding-left: 20px;
}


#admin {
    /* font-family: "MyFont"; */
    border-spacing: 0;
    width: 100%;
    color: white;
    border-collapse: collapse;
    /* make the table borders collapse to each other */
    width: 100%;
}

#admin th {
    background: #000000;
    font-size: 14px;
    position: sticky;
    z-index: 100;
}

#admin th:nth-child(2) {
    padding-right: 20px;
}


#admin td {
    /* border-bottom: 5px solid #000000; */
    padding-left: 20px;
    white-space: nowrap;
}

#admin td:nth-child(3) {
    /* border-bottom-left-radius: 20px; */
    /* background: red; */
    padding-right: 150px;

    /* border-bottom: 5px solid #000000; */
}

#admin tr {
    /* background-image: linear-gradient(97deg, rgba(10, 10, 10, 1) 0%, rgba(37, 37, 37, 1) 99%); */
    align-items: center;
    padding-left: 20px;
}



#admin th {
    padding-top: 12px;
    padding-bottom: 12px;
    color: white;
    padding-left: 20px;
}


.fixed_header {
    border-collapse: collapse;
    border-spacing: 0;
    width: 100%;
    /* border: 1px solid #ddd; */
}



.fixed_header th,
.fixed_header td {
    text-align: left;
    padding: 8px;
}


/*cardbody-cardallowance*/

#CardBodyCardAllowance {
    background-image: linear-gradient(135deg, #0A0A0A, #373737 99%);
    border-radius: 4px;
    font-family: "MyFont";
    box-shadow: 3px 5px 6px #000000;
    height: 198px;
}

#CardBodyCardAllowance2 {
    background-image: linear-gradient(135deg, #000000, #272727 99%);
    border-radius: 15px;
    /* font-family: "MyFont"; */
    /* box-shadow: 1px 5px 1px #000000; */
    box-shadow: 3px 5px 6px #000000;
    height: 720px;
    margin-top: -5px;
}

#CardBodyCardAllowanceVSP {
    background-image: linear-gradient(135deg, #101010 20%, #1B1B1B);
    border-radius: 4px;
    /* font-family: "MyFont"; */
    box-shadow: 3px 5px 6px #000000;
    height: 495px;
    margin-top: -5px;
}

#CardBodyCardAllowance3 {
    /* background-image: linear-gradient(135deg, #000000, #272727 99%); */
    background-color: transparent;

    /* border-bottom-left-radius: 10px; */
    /* font-family: "MyFont"; */
    /* box-shadow: 3px 5px 6px #000000; */
    position: block;
    display: flex;
    border: 0;
    /* height: 720px;
    margin-top: -5px; */

}

#CardBodyCardAllowance7 {
    /* background-image: linear-gradient(135deg, #101010 10%, #1B1B1B 60%); */
    background-image: linear-gradient(135deg, #101010, #1B1B1B 50%);
    /* border-bottom-left-radius: 10px; */
    /* font-family: "MyFont"; */
    box-shadow: 3px 5px 6px #000000;
    /* height: 720px;
    margin-top: -5px; */

}

#CardBodyCardAllowance8 {
    background-image: linear-gradient(135deg, #101010, #1B1B1B 50%);
    border-radius: 4px;
    /* font-family: "MyFont"; */
    box-shadow: 3px 5px 6px #000000;
    height: 720px;
    /* height: 720px;
    margin-top: -5px; */

}

#CardBodyCardAllowance1 {
    background-image: linear-gradient(135deg, #101010, #373737 99%);
    border-radius: 4px;
    font-family: "MyFont";
    box-shadow: 3px 5px 6px #000000;
    /* height: 130px; */
    height: 14vh;
}

#CardBodyCardAllowance4 {
    background-image: linear-gradient(135deg, #101010, #373737 99%);
    border-radius: 4px;
    font-family: "MyFont";
    box-shadow: 3px 5px 6px #000000;
    /* height: 450px; */
    height: 51.2vh;

}

#CardBodyCardAllowance5 {
    background-image: linear-gradient(135deg, #101010, #373737 99%);
    border-radius: 4px;
    font-family: "MyFont";
    box-shadow: 3px 5px 6px #000000;
    height: 200px;
}

#CardBodyCardAllowance6 {
    background-image: linear-gradient(135deg, #101010, #373737 99%);
    border-radius: 4px;
    font-family: "MyFont";
    box-shadow: 3px 5px 6px #000000;
}

.tableresponsive {
    width: 100%;
    overflow-y: auto;
}

#CardBody4layers {
    background-image: linear-gradient(135deg, #000000 20%, #1b1b1b 99%);
    border-radius: 4px;
    font-family: "MyFont";
    border-collapse: separate;
    border-spacing: 0;
    width: 100%;
    padding: 10px;
}


#CardBody4layers td {
    color: white;
    white-space: nowrap;
    /*word-break: break-all;*/
}




#CardBody4layers tr {
    align-items: center;
    color: white;
}

#CardBody4layers td:first-child {
    font-size: 55px;
    font-family: "MyFontMedium";
    color: #A9A9A9;
    padding-left: 5px;
}

#CardBody4layers td:nth-child(2) {
    font-size: 18px;
    padding-left: 20px;
    right: 20px;
    color: #A9A9A9;
}

#CardBody4layers td:last-child {
    /*float: right;*/
    /*  padding-top: 13px;*/
    text-align: right
}

/* #CardBody4layers tr:first-child {
        display: none;
    }*/
/*car allowance button*/
#carallowancebtn {
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 0px;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    background-color: transparent;
    width: 100%;
    border-color: #DC1860;
    color: #DC1860;
    text-align: left;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: -5px 2px;
    cursor: pointer;
    height: 50px;
    /* margin: 0; */
}

#employeecarbtn {
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 0px;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    background-color: transparent;
    width: 100%;
    border-color: Turquoise;
    color: Turquoise;
}

#ArrowRightIconstyle {
    float: right;
}

.table th,
.table td {
    padding: 1rem;
    vertical-align: middle;
}

.tableFixHead {
    height: 93vh;
    /* gives an initial height of 200px to the table */
    overflow: auto;
    /* make the table scrollable if height is more than 200 px  */
    overflow-x: hidden;
    margin-top: 30px;
}

@media screen and (max-width: 600px) {
    .tableFixHead {
        height: 90vh;
        margin-left: -100px;
        margin-top: -10px;
    }
}

.tableFixHead thead th {
    position: sticky;
    /*make the table heads sticky */
    top: 0px;
    /*table head will be placed from the top of the table and sticks to it */
}



.tableFixHeadCarPool {
    height: 680px;
    /* gives an initial height of 200px to the table */
    overflow: auto;
    /* make the table scrollable if height is more than 200 px  */
    overflow-x: hidden;
    margin-top: -110px;
    margin-left: -97px;
    margin-right: -33px;
}

/* @media only screen and (min-width: 600px) and (max-width: 1110px) {
    .tableFixHeadCarPool {
        overflow-x: hidden;
        margin-left: -100px;
        margin-right: 30px;
    }
} */

@media only screen and (min-width: 600px) and (max-width: 850px) {
    .tableFixHeadCarPool {
        overflow-x: hidden;
        margin-left: -100px;
        margin-right: 30px;
    }
}

@media only screen and (min-width: 1026px) and (max-width: 1110px) {
    .tableFixHeadCarPool {
        overflow-x: hidden;
        margin-left: -100px;
        margin-right: 30px;
    }
}

@media only screen and (min-width: 851px) and (max-width: 1025px) {
    .tableFixHeadCarPool {
        overflow-x: hidden;
        margin-left: -100px;
        margin-right: 30px;
        height: 500px;
    }
}

@media screen and (min-width: 1276) and (max-width: 1400px) {
    .tableFixHeadCarPool {
        margin-top: -110px;
        margin-left: -105px;
        margin-right: -78px;
    }
}

@media screen and (min-width: 1401px) and (max-width: 1445px) {
    .tableFixHeadCarPool {
        margin-top: -110px;
        margin-left: -105px;
        margin-right: -82px;
        height: 500px;
    }
}

@media screen and (min-width: 1446px) and (max-width: 1600px) {
    .tableFixHeadCarPool {
        margin-top: -110px;
        margin-left: -105px;
        margin-right: -82px;
        height: 550px;
    }
}

@media only screen and (min-width: 100px) and (max-width: 599px) {
    .tableFixHeadCarPool {
        overflow-x: auto;
        margin-left: -100px;
        margin-right: 60px;
        margin-top: -165px;
    }
}


@media only screen and (min-width: 1111px) and (max-width: 1275px) {
    .tableFixHeadCarPool {
        /* overflow-x: auto; */
        overflow-y: hidden;
        height: 400px;
        margin-top: -130px;
        height: 620px;
    }
}


.tableFixHeadCarPool thead th {
    position: sticky;
    /*make the table heads sticky */
    top: 0px;
    /*table head will be placed from the top of the table and sticks to it */
}

.tableFixHeadUnassigned {
    height: 450px;
    /* gives an initial height of 200px to the table */
    overflow: auto;
    /* make the table scrollable if height is more than 200 px  */
    overflow-x: hidden;
    overflow-y: hidden;
    margin-top: -100px;
    margin-left: -32px;
    margin-right: 160px;
}

@media only screen and (min-width: 100px) and (max-width: 599px) {
    .tableFixHeadUnassigned {
        overflow-x: auto;
        margin-left: -100px;
        margin-right: 60px;
        margin-top: -165px;
    }
}

@media only screen and (min-width: 600px) and (max-width: 850px) {
    .tableFixHeadUnassigned {
        overflow-x: hidden;
        margin-left: -90px;
        margin-right: 160px;
        margin-top: -10px;
        height: 650px;
    }
}

@media only screen and (min-width: 1026px) and (max-width: 1110px) {
    .tableFixHeadUnassigned {
        overflow-x: hidden;
        margin-left: -96px;
        margin-right: 170px;
        margin-top: 5px;
        height: 600px;
    }
}

@media only screen and (min-width: 851px) and (max-width: 1025px) {
    .tableFixHeadUnassigned {
        overflow-x: hidden;
        overflow-y: hidden;
        margin-left: -88px;
        margin-right: 170px;
        margin-top: 15px;
        height: 500px;
    }
}

@media only screen and (min-width: 1111px) and (max-width: 1275px) {
    .tableFixHeadUnassigned {
        /* overflow-x: auto; */
        /* overflow-y: hidden; */
        height: 350px;
        margin-top: -5px;

    }
}

@media screen and (min-width: 1276) and (max-width: 1400px) {
    .tableFixHeadUnassigned {
        height: 600px;
        margin-top: -80px;
        margin-left: -5px;
        margin-right: 140px;
    }
}


@media screen and (min-width: 1401px) and (max-width: 1450px) {
    .tableFixHeadUnassigned {
        height: 500px;
        margin-top: -80px;
        margin-left: -23px;
        margin-right: 130px;
        overflow-y: hidden;
        /* overflow-x: hidden; */
    }
}

@media screen and (min-width: 1451px) and (max-width: 1600px) {
    .tableFixHeadUnassigned {
        height: 600px;
        margin-top: -80px;
        margin-left: -15px;
        margin-right: 135px;
        overflow-y: hidden;
        /* overflow-x: hidden; */
    }
}

@media screen and (min-width: 1601px) and (max-width: 1800px) {
    .tableFixHeadUnassigned {
        height: 680px;
        /* gives an initial height of 200px to the table */
        overflow: auto;
        /* make the table scrollable if height is more than 200 px  */
        overflow-x: hidden;
        margin-top: 10px;
        margin-left: 13px;
        margin-right: 5px;
    }
}

@media screen and (min-width: 1801px) and (max-width: 2600px) {
    .tableFixHeadUnassigned {
        height: 680px;
        /* gives an initial height of 200px to the table */
        overflow: auto;
        /* make the table scrollable if height is more than 200 px  */
        overflow-x: hidden;
        margin-top: -130px;
        margin-left: 8px;
        margin-right: 10px;
    }
}

.tableFixHeadUnassigned thead th {
    position: sticky;
    /*make the table heads sticky */
    top: 0px;
    /*table head will be placed from the top of the table and sticks to it */
}

.tableFixHeadAssigned {
    height: 450px;
    /* gives an initial height of 200px to the table */
    overflow: auto;
    /* make the table scrollable if height is more than 200 px  */
    overflow-x: hidden;
    overflow-y: hidden;
    margin-top: -90px;
    margin-left: -40px;
    margin-right: 160px;
}

@media only screen and (min-width: 100px) and (max-width: 599px) {
    .tableFixHeadAssigned {
        overflow-x: auto;
        margin-left: -100px;
        margin-right: 60px;
        margin-top: -165px;
    }
}

@media only screen and (min-width: 600px) and (max-width: 850px) {
    .tableFixHeadAssigned {
        overflow-x: hidden;
        margin-left: -80px;
        margin-right: 170px;
        margin-top: -10px;
        height: 650px;
    }
}

@media only screen and (min-width: 851px) and (max-width: 1025px) {
    .tableFixHeadAssigned {
        overflow-x: hidden;
        overflow-y: hidden;
        margin-left: -80px;
        margin-right: 170px;
        margin-top: 5px;
        height: 500px;
    }
}

@media only screen and (min-width: 1026px) and (max-width: 1110px) {
    .tableFixHeadAssigned {
        overflow-x: hidden;
        overflow-y: hidden;
        margin-left: -80px;
        margin-right: 170px;
        margin-top: 5px;
        height: 600px;
    }
}

@media only screen and (min-width: 1111px) and (max-width: 1275px) {
    .tableFixHeadAssigned {
        /* overflow-x: auto; */
        /* overflow-y: hidden; */
        margin-left: -40px;
        height: 350px;
        margin-top: -5px;

    }
}

@media screen and (min-width: 1276) and (max-width: 1400px) {
    .tableFixHeadAssigned {
        height: 600px;
        margin-top: -80px;
        margin-left: -5px;
        margin-right: 140px;
    }
}

@media screen and (min-width: 1451px) and (max-width: 1600px) {
    .tableFixHeadAssigned {
        height: 550px;
        margin-top: -80px;
        margin-left: -35px;
        margin-right: 135px;
        overflow-y: hidden;
        /* overflow-x: hidden; */
    }
}

@media screen and (min-width: 1401px) and (max-width: 1450px) {
    .tableFixHeadAssigned {
        height: 500px;
        margin-top: -80px;
        margin-left: -38px;
        margin-right: 130px;
        overflow-y: hidden;
        /* overflow-x: hidden; */
    }
}

@media screen and (min-width: 1601px) and (max-width: 1800px) {
    .tableFixHeadAssigned {
        height: 680px;
        /* gives an initial height of 200px to the table */
        overflow: auto;
        /* make the table scrollable if height is more than 200 px  */
        overflow-x: hidden;
        margin-top: 10px;
        margin-left: -15px;
        margin-right: 10px;
    }
}

@media screen and (min-width: 1801px) and (max-width: 2600px) {
    .tableFixHeadAssigned {
        height: 680px;
        /* gives an initial height of 200px to the table */
        /* overflow: auto; */
        /* make the table scrollable if height is more than 200 px  */
        /* overflow-x: hidden; */
        margin-top: -130px;
        margin-left: 5px;
        margin-right: 10px;
    }
}

.tableFixHeadAssigned thead th {
    position: sticky;
    /*make the table heads sticky */
    top: 0px;
    /*table head will be placed from the top of the table and sticks to it */
}

.fontStylingDriver {
    float: left;
    font-size: 30rem;
    margin-top: 25px;
    background: -webkit-linear-gradient(270deg, rgba(34, 34, 34, 1) 40%, rgba(5, 5, 5, 0.5) 80%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    animation: fadeIn 5s;
    -webkit-animation: fadeIn 5s;
    -moz-animation: fadeIn 5s;
    -o-animation: fadeIn 5s;
    -ms-animation: fadeIn 5s;
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@-moz-keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@-webkit-keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@-o-keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@-ms-keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@media screen and (min-width: 100px) {
    .fontStylingDriver {
        font-size: 2rem;
        margin-bottom: 180px;
        margin-top: 105px;
        text-wrap: nowrap;
        white-space: nowrap;
        margin-left: -140px;
    }
}

.fontStylingDriver {
    font-size: 8rem;
    margin-top: 65px;
    margin-bottom: 80px;
    padding-left: 17px;
    text-wrap: nowrap;
    white-space: nowrap;
}

@media screen and (min-width: 1401px) and (max-width: 1600px) {
    .fontStylingDriver {
        font-size: 7rem;
        margin-top: 70px;
        margin-bottom: 90px;
        padding-left: 10px;
        text-wrap: nowrap;
        white-space: nowrap;
    }
}

@media only screen and (min-width: 1111px) and (max-width: 1400px) {
    .fontStylingDriver {
        font-size: 6rem;
        margin-bottom: 40px;
        margin-top: 70px;
        padding-left: 17px;
        margin-bottom: 110px;
        text-wrap: nowrap;
        white-space: nowrap;
    }
}

/* @media only screen and (min-width: 600px) and (max-width: 1000px) {
    .fontStylingDriver {
        font-size: 3rem;
        margin-bottom: 130px;
        margin-top: 85px;
        margin-left: -16.7vw;
        text-wrap: nowrap;
        white-space: nowrap;
    }
} */

@media only screen and (min-width: 600px) and (max-width: 770px) {
    .fontStylingDriver {
        font-size: 3rem;
        margin-bottom: 130px;
        margin-top: 85px;
        margin-left: -18.2vw;
        text-wrap: nowrap;
        white-space: nowrap;
    }
}

@media only screen and (min-width: 771px) and (max-width: 1000px) {
   .fontStylingDriver {
        font-size: 3rem;
        margin-bottom: 130px;
        margin-top: 85px;
        margin-left: -16.7vw;
        text-wrap: nowrap;
        white-space: nowrap;
    }
}

@media only screen and (min-width: 1001px) and (max-width: 1025px) {
    .fontStylingDriver {
        font-size: 4rem;
        margin-bottom: 130px;
        margin-top: 85px;
        margin-left: -14vw;
        text-wrap: nowrap;
        white-space: nowrap;
    }
}

@media only screen and (min-width: 1026px) and (max-width: 1110px) {
    .fontStylingDriver {
        font-size: 4rem;
        margin-bottom: 130px;
        margin-top: 85px;
        margin-left: -12.9vw;
        text-wrap: nowrap;
        white-space: nowrap;
    }
}

@media screen and (min-width: 1801px) and (max-width: 2600px) {
    .fontStylingDriver {
        font-size: 8rem;
        padding-left: 17px;
        margin-top: 70px;
        margin-bottom: 70px;
        text-wrap: nowrap;
        white-space: nowrap;
    }
}

@media screen and (min-width: 2601px) and (max-width: 4600px) {
    .fontStylingDriver {
        font-size: 14.5rem;
        margin-left: -6vw;
        margin-top: 40px;
        text-wrap: nowrap;
        white-space: nowrap;
        margin-bottom: 70px;
    }
}


.fontStylingUnassigned {
    float: left;
    font-size: 30rem;
    margin-top: 25px;
    background: -webkit-linear-gradient(270deg, rgba(34, 34, 34, 1) 40%, rgba(5, 5, 5, 0.5) 80%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    animation: fadeIn 5s;
    -webkit-animation: fadeIn 5s;
    -moz-animation: fadeIn 5s;
    -o-animation: fadeIn 5s;
    -ms-animation: fadeIn 5s;
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@-moz-keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@-webkit-keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@-o-keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@-ms-keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@media screen and (min-width: 100px) {
    .fontStylingUnassigned {
        font-size: 2rem;
        margin-bottom: 180px;
        margin-top: 105px;
        text-wrap: nowrap;
        white-space: nowrap;
        margin-left: -140px;
    }
}

@media screen and (min-width: 1601px) and (max-width: 1800px) {
    .fontStylingUnassigned {
        font-size: 8rem;
        margin-top: 65px;
        padding-left: 33px;
        text-wrap: nowrap;
        white-space: nowrap;
    }
}

@media screen and (min-width: 1401px) and (max-width: 1600px) {
    .fontStylingUnassigned {
        font-size: 7rem;
        margin-top: 70px;
        margin-bottom: 90px;
        padding-left: 22px;
        text-wrap: nowrap;
        white-space: nowrap;
    }
}

@media only screen and (min-width: 1301px) and (max-width: 1400px) {
    .fontStylingUnassigned {
        font-size: 6rem;
        margin-bottom: 40px;
        margin-top: 70px;
        padding-left: 17px;
        margin-bottom: 110px;
        text-wrap: nowrap;
        white-space: nowrap;
    }
}

@media only screen and (min-width: 1111px) and (max-width: 1300px) {
    .fontStylingUnassigned {
        font-size: 5rem;
        margin-bottom: 40px;
        margin-top: 70px;
        padding-left: 28px;
        text-wrap: nowrap;
        white-space: nowrap;
    }
}

@media only screen and (min-width: 600px) and (max-width: 770px) {
    .fontStylingUnassigned {
        font-size: 3rem;
        margin-bottom: 130px;
        margin-top: 85px;
        margin-left: -18.8vw;
        text-wrap: nowrap;
        white-space: nowrap;
    }
}

@media only screen and (min-width: 771px) and (max-width: 1000px) {
    .fontStylingUnassigned {
        font-size: 3rem;
        margin-bottom: 130px;
        margin-top: 85px;
        margin-left: -18vw;
        text-wrap: nowrap;
        white-space: nowrap;
    }
}

@media only screen and (min-width: 1001px) and (max-width: 1110px) {
    .fontStylingUnassigned {
        font-size: 4rem;
        margin-bottom: 130px;
        margin-top: 85px;
        margin-left: -15.4vw;
        text-wrap: nowrap;
        white-space: nowrap;
    }
}

@media screen and (min-width: 1801px) and (max-width: 2600px) {
    .fontStylingUnassigned {
        font-size: 8rem;
        padding-left: 26px;
        margin-top: 70px;
        margin-bottom: 70px;
        text-wrap: nowrap;
        white-space: nowrap;
    }
}

@media screen and (min-width: 2601px) and (max-width: 4600px) {
    .fontStylingUnassigned {
        font-size: 8rem;
        margin-left: -7vw;
        margin-top: 40px;
        text-wrap: nowrap;
        white-space: nowrap;
        margin-bottom: 70px;
    }
}

.fontStylingAssigned {
    float: left;
    font-size: 30rem;
    margin-top: 25px;
    background: -webkit-linear-gradient(270deg, rgba(34, 34, 34, 1) 40%, rgba(5, 5, 5, 0.5) 80%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    animation: fadeIn 5s;
    -webkit-animation: fadeIn 5s;
    -moz-animation: fadeIn 5s;
    -o-animation: fadeIn 5s;
    -ms-animation: fadeIn 5s;
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@-moz-keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@-webkit-keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@-o-keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@-ms-keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@media screen and (min-width: 100px) {
    .fontStylingAssigned {
        font-size: 2rem;
        margin-bottom: 180px;
        margin-top: 105px;
        text-wrap: nowrap;
        white-space: nowrap;
        margin-left: -140px;
    }
}

.fontStylingAssigned {
    font-size: 8rem;
    margin-top: 65px;
    margin-bottom: 80px;
    padding-left: 17px;
    text-wrap: nowrap;
    white-space: nowrap;
}

@media screen and (min-width: 1401px) and (max-width: 1600px) {
    .fontStylingAssigned {
        font-size: 7rem;
        margin-top: 70px;
        margin-bottom: 90px;
        padding-left: 10px;
        text-wrap: nowrap;
        white-space: nowrap;
    }
}

@media only screen and (min-width: 1111px) and (max-width: 1400px) {
    .fontStylingAssigned {
        font-size: 6rem;
        margin-bottom: 40px;
        margin-top: 70px;
        padding-left: 17px;
        margin-bottom: 110px;
        text-wrap: nowrap;
        white-space: nowrap;
    }
}

@media only screen and (min-width: 600px) and (max-width: 770px) {
    .fontStylingAssigned {
        font-size: 3rem;
        margin-bottom: 130px;
        margin-top: 85px;
        margin-left: -19.2vw;
        text-wrap: nowrap;
        white-space: nowrap;
    }
}

@media only screen and (min-width: 771px) and (max-width: 1000px) {
    .fontStylingAssigned {
        font-size: 3rem;
        margin-bottom: 130px;
        margin-top: 85px;
        margin-left: -18.2vw;
        text-wrap: nowrap;
        white-space: nowrap;
    }
}

@media only screen and (min-width: 1001px) and (max-width: 1110px) {
    .fontStylingAssigned {
        font-size: 4rem;
        margin-bottom: 130px;
        margin-top: 85px;
        margin-left: -15.3vw;
        text-wrap: nowrap;
        white-space: nowrap;
    }
}

@media screen and (min-width: 1801px) and (max-width: 2600px) {
    .fontStylingAssigned {
        font-size: 8rem;
        padding-left: 22px;
        margin-top: 70px;
        margin-bottom: 70px;
        text-wrap: nowrap;
        white-space: nowrap;
    }
}

@media screen and (min-width: 2601px) and (max-width: 4600px) {
    .fontStylingAssigned {
        font-size: 14.5rem;
        margin-left: -6vw;
        margin-top: 40px;
        text-wrap: nowrap;
        white-space: nowrap;
        margin-bottom: 70px;
    }
}

.fontStylingResolved {
    float: left;
    font-size: 30rem;
    margin-top: 25px;
    background: -webkit-linear-gradient(270deg, rgba(34, 34, 34, 1) 40%, rgba(5, 5, 5, 0.5) 80%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    animation: fadeIn 5s;
    -webkit-animation: fadeIn 5s;
    -moz-animation: fadeIn 5s;
    -o-animation: fadeIn 5s;
    -ms-animation: fadeIn 5s;
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@-moz-keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@-webkit-keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@-o-keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@-ms-keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@media screen and (min-width: 100px) {
    .fontStylingResolved {
        font-size: 2rem;
        margin-bottom: 180px;
        margin-top: 105px;
        text-wrap: nowrap;
        white-space: nowrap;
        margin-left: -140px;
    }
}

@media screen and (min-width: 1601px) and (max-width: 1800px) {
    .fontStylingResolved {
        font-size: 8rem;
        margin-top: 65px;
        margin-bottom: 80px;
        padding-left: 10px;
        text-wrap: nowrap;
        white-space: nowrap;
    }
}


@media screen and (min-width: 1451px) and (max-width: 1600px) {
    .fontStylingResolved {
        font-size: 7rem;
        margin-top: 70px;
        margin-bottom: 90px;
        padding-left: 3px;
        text-wrap: nowrap;
        white-space: nowrap;
    }
}

@media screen and (min-width: 1401px) and (max-width: 1450px) {
    .fontStylingResolved {
        font-size: 7rem;
        margin-top: 70px;
        margin-bottom: 90px;
        padding-left: 7px;
        text-wrap: nowrap;
        white-space: nowrap;
    }
}

@media only screen and (min-width: 1276px) and (max-width: 1400px) {
    .fontStylingResolved {
        font-size: 6rem;
        margin-bottom: 40px;
        margin-top: 70px;
        padding-left: 12px;
        margin-bottom: 110px;
        text-wrap: nowrap;
        white-space: nowrap;
    }
}


@media only screen and (min-width: 1111px) and (max-width: 1275px) {
    .fontStylingResolved {
        font-size: 6rem;
        margin-bottom: 40px;
        margin-top: 70px;
        padding-left: 17px;
        margin-bottom: 110px;
        text-wrap: nowrap;
        white-space: nowrap;
    }
}

@media only screen and (min-width: 600px) and (max-width: 770px) {
    .fontStylingResolved {
        font-size: 3rem;
        margin-bottom: 130px;
        margin-top: 85px;
        margin-left: -17.8vw;
        text-wrap: nowrap;
        white-space: nowrap;
    }
}

@media only screen and (min-width: 771px) and (max-width: 1000px) {
    .fontStylingResolved {
        font-size: 3rem;
        margin-bottom: 130px;
        margin-top: 85px;
        margin-left: -16.5vw;
        text-wrap: nowrap;
        white-space: nowrap;
    }
}

@media only screen and (min-width: 1001px) and (max-width: 1110px) {
    .fontStylingResolved {
        font-size: 4rem;
        margin-bottom: 130px;
        margin-top: 85px;
        margin-left: -14vw;
        text-wrap: nowrap;
        white-space: nowrap;
    }
}

@media screen and (min-width: 1801px) and (max-width: 2600px) {
    .fontStylingResolved {
        font-size: 8rem;
        padding-left: 22px;
        margin-top: 70px;
        margin-bottom: 70px;
        text-wrap: nowrap;
        white-space: nowrap;
    }
}

@media screen and (min-width: 2601px) and (max-width: 4600px) {
    .fontStylingResolved {
        font-size: 14.5rem;
        margin-left: -6vw;
        margin-top: 40px;
        text-wrap: nowrap;
        white-space: nowrap;
        margin-bottom: 70px;
    }
}

.searchstyledriver {
    margin-top: 166px;
    cursor: pointer;
    margin-left: 24vw;
    width: 100%;
    transition: filter 0.3s ease;
}

@media only screen and (min-width: 100px) and (max-width: 599px) {
    .searchstyledriver {
        width: 810%;
        margin-left: -14vw;
        margin-top: 116px;
        margin-bottom: -18px;
    }
}

@media screen and (min-width: 1601px) and (max-width: 1800px) {
    .searchstyledriver {
        width: 500%;
        margin-left: 17vw;
        margin-top: 210px;
    }
}

@media screen and (min-width: 1401px) and (max-width: 1600px) {
    .searchstyledriver {
        width: 300%;
        margin-left: 16vw;
        margin-top: 142px;
    }
}

@media only screen and (min-width: 1111px) and (max-width: 1400px) {
    .searchstyledriver {
        width: 300%;
        margin-left: 15.4vw;
        margin-top: 140px;
    }
}

@media only screen and (min-width: 600px) and (max-width: 1000px) {
    .searchstyledriver {
        width: 500%;
        margin-left: 5vw;
        margin-top: 85px;
    }
}

@media only screen and (min-width: 1001px) and (max-width: 1110px) {
    .searchstyledriver {
        width: 500%;
        margin-left: 3vw;
        margin-top: 86px;
    }
}

@media screen and (min-width: 1801px) and (max-width: 2600px) {
    .searchstyledriver {
        width: 320%;
        margin-left: 24vw;
        margin-top: 170px;
    }
}

@media screen and (min-width: 2601px) and (max-width: 4600px) {
    .searchstyledriver {
        width: 150%;
        margin-left: 17.5vw;
        margin-top: 230px;
    }
}

.magnifyIconMenu {
    height: 38px;
    width: 38px;
    margin-top: -16px;
    cursor: pointer;
    margin-left: 4.5vw;
    transition: filter 0.3s ease;
}

@media screen and (min-width: 100px) {
    .magnifyIconMenu {
        margin-left: 5vw;
        margin-top: 76px;
    }
}

@media screen and (min-width: 1601px) and (max-width: 1800px) {
    .magnifyIconMenu {
        margin-left: 22vw;
        margin-top: 200px;
    }
}

@media screen and (min-width: 1401px) and (max-width: 1600px) {
    .magnifyIconMenu {
        margin-left: 22vw;
        margin-top: 106px;
    }
}

@media only screen and (min-width: 1111px) and (max-width: 1400px) {
    .magnifyIconMenu {
        margin-left: 22vw;
        margin-top: 106px;
    }
}

@media only screen and (min-width: 600px) and (max-width: 1110px) {
    .magnifyIconMenu {
        margin-left: 15vw;
        margin-top: 52px;
    }
}

@media screen and (min-width: 1801px) and (max-width: 2600px) {
    .magnifyIconMenu {
        margin-left: 28vw;
        margin-top: 156px;
    }
}

@media screen and (min-width: 2601px) and (max-width: 4600px) {
    .magnifyIconMenu {
        margin-left: 20vw;
        margin-top: 196px;
    }
}

.magnifyIconUnassignedCases {
    height: 38px;
    width: 38px;
    cursor: pointer;
    transition: filter 0.3s ease;
    margin-left: 34.2vw;
    margin-top: 100px;
    margin-bottom: -20px;
}

@media screen and (min-width: 100px) {
    .magnifyIconUnassignedCases {
        margin-left: -50px;
        margin-top: 105px;
    }
}

@media screen and (min-width: 1601px) and (max-width: 1800px) {
    .magnifyIconUnassignedCases {
        /* margin-top: 225px; */
        margin-left: 34.5vw;
    }
}

@media screen and (min-width: 1401px) and (max-width: 1450px) {
    .magnifyIconUnassignedCases {
        margin-left: 150px;
        margin-top: 194px;
    }
}

@media screen and (min-width: 1451px) and (max-width: 1600px) {
    .magnifyIconUnassignedCases {
        margin-left: 150px;
        margin-top: 145px;
    }
}

@media only screen and (min-width: 1276px) and (max-width: 1400px) {
    .magnifyIconUnassignedCases {
        margin-left: 82px;
        margin-top: 25px;
    }
}

@media only screen and (min-width: 1111px) and (max-width: 1275px) {
    .magnifyIconUnassignedCases {
        margin-left: 75px;
        margin-top: 90px;
    }
}

@media only screen and (min-width: 600px) and (max-width: 1000px) {
    .magnifyIconUnassignedCases {
        margin-left: -32px;
        margin-top: -58px;
    }
}

@media only screen and (min-width: 1026px) and (max-width: 1110px) {
    .magnifyIconUnassignedCases {
        margin-left: 2px;
        margin-top: -40px;
    }
}

@media only screen and (min-width: 1001px) and (max-width: 1025px) {
    .magnifyIconUnassignedCases {
        margin-left: 2px;
        margin-top: 157px;
    }
}

@media screen and (min-width: 1801px) and (max-width: 2600px) {
    .magnifyIconUnassignedCases {
        margin-top: 37px;
        margin-left: 12.82vw;
    }
}

@media screen and (min-width: 2601px) and (max-width: 4600px) {
    .magnifyIconUnassignedCases {
        margin-top: 225px;
        margin-left: 11.53vw;
    }
}

.magnifyIconAssignedCases {
    height: 38px;
    width: 38px;
    cursor: pointer;
    transition: filter 0.3s ease;
    margin-left: 36vw;
    margin-top: 100px;
    margin-bottom: -20px;
}

@media screen and (min-width: 1601px) and (max-width: 1800px) {
    .magnifyIconAssignedCases {
        /* margin-top: 225px; */
        margin-left: 37.6vw;
    }
}

@media screen and (min-width: 1451px) and (max-width: 1600px) {
    .magnifyIconAssignedCases {
        margin-left: 220px;
        margin-top: 145px;
    }
}

@media screen and (min-width: 1401px) and (max-width: 1450px) {
    .magnifyIconAssignedCases {
        margin-left: 200px;
        margin-top: 192px;
    }
}

@media only screen and (min-width: 1276px) and (max-width: 1400px) {
    .magnifyIconAssignedCases {
        margin-left: 140px;
        margin-top: 35px;
    }
}

@media only screen and (min-width: 1111px) and (max-width: 1275px) {
    .magnifyIconAssignedCases {
        margin-left: 125px;
        margin-top: 115px;
    }
}

@media only screen and (min-width: 600px) and (max-width: 770px) {
    .magnifyIconAssignedCases {
        margin-left: -55px;
        margin-top: -58px;
    }
}

@media only screen and (min-width: 771px) and (max-width: 1000px) {
    .magnifyIconAssignedCases {
        margin-left: -20px;
        margin-top: -58px;
    }
}

@media only screen and (min-width: 1001px) and (max-width: 1025px) {
    .magnifyIconAssignedCases {
        margin-left: 60px;
        margin-top: 162px;
    }
}

@media only screen and (min-width: 1026px) and (max-width: 1110px) {
    .magnifyIconAssignedCases {
        margin-left: 72px;
        margin-top: -28px;
    }
}

@media screen and (min-width: 1801px) and (max-width: 2600px) {
    .magnifyIconAssignedCases {
        margin-top: 20px;
        margin-left: 18.82vw;
    }
}

@media screen and (min-width: 2601px) and (max-width: 4600px) {
    .magnifyIconAssignedCases {
        margin-top: 225px;
        margin-left: 11.53vw;
    }
}

.magnifyIconResolvedCases {
    height: 38px;
    width: 38px;
    cursor: pointer;
    transition: filter 0.3s ease;
    margin-left: 36vw;
    margin-top: -30px;
    margin-bottom: -20px;
}


.dotcircle {
    height: 31px;
    width: 31px;
    cursor: pointer;
    transition: filter 0.3s ease;
    margin-top: 185px;
    margin-left: 13vw;
    /* margin-top: -3px; */
    /* margin-right: -1px; */
}

@media screen and (min-width: 100px) {
    .dotcircle {
        margin-left: -50px;
        margin-top: 105px;
    }
}

@media screen and (min-width: 1601px) and (max-width: 1800px) {
    .dotcircle {
        margin-top: 225px;
        margin-left: 12.82vw;
    }
}

@media screen and (min-width: 1401px) and (max-width: 1600px) {
    .dotcircle {
        margin-left: 148px;
        margin-top: 135px;
    }
}

@media only screen and (min-width: 1111px) and (max-width: 1400px) {
    .dotcircle {
        margin-left: 125px;
        margin-top: 135px;
    }
}

@media only screen and (min-width: 600px) and (max-width: 1000px) {
    .dotcircle {
        margin-left: -25px;
        margin-top: 88px;
    }
}

@media only screen and (min-width: 1001px) and (max-width: 1110px) {
    .dotcircle {
        margin-left: 2px;
        margin-top: 104px;
    }
}

@media screen and (min-width: 1801px) and (max-width: 2600px) {
    .dotcircle {
        margin-top: 180px;
        margin-left: 12.82vw;
    }
}

@media screen and (min-width: 2601px) and (max-width: 4600px) {
    .dotcircle {
        margin-top: 225px;
        margin-left: 11.53vw;
    }
}

#tablecard {
    background-color: transparent;
    width: 100%;
    border-radius: 15px;

}

@media screen and (min-width: 100px) {
    #tablecard {
        margin-left: 63px;
    }
}

@media screen and (min-width: 1601px) and (max-width: 1800px) {
    #tablecard {
        margin-left: -10px;
    }
}

@media screen and (min-width: 1401px) and (max-width: 1600px) {
    #tablecard {
        margin-left: 80px;
        padding-right: 270px;
    }
}

@media only screen and (min-width: 1111px) and (max-width: 1400px) {
    #tablecard {
        margin-left: 80px;
        padding-right: 150px;
    }
}

@media only screen and (min-width: 600px) and (max-width: 1110px) {
    #tablecard {
        margin-left: 120px;
        padding-right: 120px;
    }
}

@media screen and (min-width: 1801px) and (max-width: 2600px) {
    #tablecard {
        margin-left: 0px;
    }
}

@media screen and (min-width: 2601px) and (max-width: 4600px) {
    #tablecard {
        margin-left: -170px;
    }
}

.rowheight {
    margin-top: 0px;
}

@media only screen and (min-width: 600px) and (max-width: 1110px) {
    .rowheight {
        margin-top: 30px;
    }
}

@media only screen and (min-width: 100px) and (max-width: 599px) {
    .rowheight {
        margin-top: 0px;
        margin-left: -60px;
    }
}

@media screen and (min-width: 1401px) and (max-width: 1600px) {
    .rowheight {
        margin-top: 30px;
    }
}

@media screen and (min-width: 1601px) and (max-width: 1800px) {
    .rowheight {
        margin-top: -40px;
        margin-left: -300px;
    }
}

@media screen and (min-width: 1801px) and (max-width: 2600px) {
    .rowheight {
        margin-top: 45px;
    }
}

@media screen and (min-width: 2601px) and (max-width: 4600px) {
    .rowheight {
        margin-top: 20px;
        margin-left: -120px;
    }
}

.magnifyIcon {
    height: 28px;
    width: 28px;
    cursor: pointer;
    transition: filter 0.3s ease;
    /* margin-top: -3px; */
    /* margin-right: -1px; */
}

.closebutton {
    height: 30px;
    width: 30px;
    cursor: pointer;
    transition: filter 0.3s ease;
    /* margin-top: -3px; */
    /* margin-right: -1px; */
}

.dropdownSelect {
    width: 90%;
    z-index: 20;
    margin-left: 20vw;
    margin-top: 200px;
}

@media only screen and (min-width: 600px) and (max-width: 1000px) {
    .dropdownSelect {
        width: 450%;
        margin-left: -17vw;
        margin-top: 60px;
    }
}

@media only screen and (min-width: 1001px) and (max-width: 1111px) {
    .dropdownSelect {
        width: 300%;
        margin-left: -6.1vw;
        margin-top: 78px;
    }
}

@media only screen and (min-width: 1112px) and (max-width: 1400px) {
    .dropdownSelect {
        width: 250%;
        margin-left: -2vw;
        margin-top: 135px;
    }
}

@media only screen and (min-width: 100px) and (max-width: 599px) {
    .dropdownSelect {
        margin-top: 0px;
        margin-left: -60px;
    }
}

@media screen and (min-width: 1401px) and (max-width: 1600px) {
    .dropdownSelect {
        width: 190%;
        margin-left: 6.5vw;
        margin-top: 110px;
    }
}

@media screen and (min-width: 1601px) and (max-width: 1800px) {
    .dropdownSelect {
        z-index: 20;
        margin-left: 22vw;
        margin-top: 200px;
    }
}

@media screen and (min-width: 1801px) and (max-width: 2600px) {
    .dropdownSelect {
        width: 150%;
        z-index: 20;
        margin-left: 5vw;
        margin-top: 116px;
    }
}

@media screen and (min-width: 2601px) and (max-width: 4600px) {
    .dropdownSelect {
        margin-top: 20px;
        margin-left: -120px;
    }
}


.dropdownSelectUnassigned {
    width: 90%;
    z-index: 200;
    margin-left: 17vw;
    margin-top: 200px;
}

@media only screen and (min-width: 600px) and (max-width: 770px) {
    .dropdownSelectUnassigned {
        width: 450%;
        margin-left: -13vw;
        margin-top: 60px;
    }
}

@media only screen and (min-width: 771px) and (max-width: 1000px) {
    .dropdownSelectUnassigned {
        width: 450%;
        margin-left: -17vw;
        margin-top: 60px;
    }
}

@media only screen and (min-width: 1026px) and (max-width: 1111px) {
    .dropdownSelectUnassigned {
        width: 300%;
        margin-left: -14vw;
        margin-top: 72px;
    }
}
@media only screen and (min-width: 1001px) and (max-width: 1025px) {
    .dropdownSelectUnassigned {
        width: 300%;
        margin-left: -12vw;
        margin-top: 72px;
    }
}

@media only screen and (min-width: 1281px) and (max-width: 1400px) {
    .dropdownSelectUnassigned {
        width: 250%;
        margin-left: -6vw;
        margin-top: 125px;
    }
}

@media only screen and (min-width: 1112px) and (max-width: 1280px) {
    .dropdownSelectUnassigned {
        width: 250%;
        margin-left: -6vw;
        margin-top: 110px;
    }
}

@media only screen and (min-width: 100px) and (max-width: 599px) {
    .dropdownSelectUnassigned {
        margin-top: 0px;
        margin-left: -60px;
    }
}

@media screen and (min-width: 1451px) and (max-width: 1600px) {
    .dropdownSelectUnassigned {
        width: 200%;
        margin-left: 0.7vw;
        margin-top: 114px;
    }
}

@media screen and (min-width: 1401px) and (max-width: 1450px) {
    .dropdownSelectUnassigned {
        width: 200%;
        margin-left: 2.8vw;
        margin-top: 115px;
    }
}

@media screen and (min-width: 1601px) and (max-width: 1800px) {
    .dropdownSelectUnassigned {
        z-index: 20;
        margin-left: 19.5vw;
        margin-top: 203px;
    }
}

@media screen and (min-width: 1801px) and (max-width: 2600px) {
    .dropdownSelectUnassigned {
        width: 170%;
        z-index: 20;
        margin-left: -5vw;
        margin-top: 135px;
    }
}

@media screen and (min-width: 2601px) and (max-width: 4600px) {
    .dropdownSelectUnassigned {
        margin-top: 20px;
        margin-left: -120px;
    }
}

.searchStyleUnassignedCases {
    margin-top: 110px;
    cursor: pointer;
    margin-left: 29vw;
    width: 480%;
    margin-bottom: -25px;
    transition: filter 0.3s ease;
}

@media only screen and (min-width: 100px) and (max-width: 599px) {
    .searchStyleUnassignedCases {
        width: 810%;
        margin-left: -14vw;
        margin-top: 116px;
        margin-bottom: -18px;
    }
}

@media screen and (min-width: 1601px) and (max-width: 1800px) {
    .searchStyleUnassignedCases {
        width: 440%;
        margin-left: 30vw;
        margin-top: 112px;
    }
}

@media screen and (min-width: 1451px) and (max-width: 1600px) {
    .searchStyleUnassignedCases {
        width: 780%;
        margin-left: 5vw;
        margin-top: 155px;
    }
}

@media screen and (min-width: 1401px) and (max-width: 1450px) {
    .searchStyleUnassignedCases {
        width: 780%;
        margin-left: 5vw;
        margin-top: 28px;
    }
}

@media only screen and (min-width: 1111px) and (max-width: 1400px) {
    .searchStyleUnassignedCases {
        width: 300%;
        margin-left: 15.4vw;
        margin-top: 140px;
    }
}

@media only screen and (min-width: 600px) and (max-width: 1000px) {
    .searchStyleUnassignedCases {
        width: 1700%;
        margin-left: -13vw;
        margin-top: -35px;
    }
}

@media only screen and (min-width: 1001px) and (max-width: 1110px) {
    .searchStyleUnassignedCases {
        width: 1200%;
        margin-left: -6.5vw;
        margin-top: -20px;
    }
}

@media only screen and (min-width: 1281px) and (max-width: 1400px) {
    .searchStyleUnassignedCases {
        width: 950%;
        margin-left: -0.7vw;
        margin-top: 33px;
    }
}

@media only screen and (min-width: 1111px) and (max-width: 1280px) {
    .searchStyleUnassignedCases {
        width: 250%;
        margin-left: -6vw;
        margin-top: 110px;
    }
}

@media screen and (min-width: 1801px) and (max-width: 2600px) {
    .searchStyleUnassignedCases {
        width: 720%;
        margin-left: 8.8vw;
        margin-top: 45px;
    }
}

@media screen and (min-width: 2601px) and (max-width: 4600px) {
    .searchStyleUnassignedCases {
        width: 150%;
        margin-left: 17.5vw;
        margin-top: 230px;
    }
}

.searchStyleAssignedCases {
    margin-top: 110px;
    cursor: pointer;
    margin-left: 31.5vw;
    width: 450%;
    margin-bottom: -25px;
    transition: filter 0.3s ease;
    z-index: 20;
}

@media only screen and (min-width: 100px) and (max-width: 599px) {
    .searchStyleAssignedCases {
        width: 810%;
        margin-left: -14vw;
        margin-top: 116px;
        margin-bottom: -18px;
    }
}

@media screen and (min-width: 1601px) and (max-width: 1800px) {
    .searchStyleAssignedCases {
        width: 440%;
        margin-left: 33vw;
        margin-top: 112px;
    }
}

@media screen and (min-width: 1451px) and (max-width: 1600px) {
    .searchStyleAssignedCases {
        width: 780%;
        margin-left: 9vw;
        margin-top: 155px;
    }
}

@media screen and (min-width: 1401px) and (max-width: 1450px) {
    .searchStyleAssignedCases {
        width: 780%;
        margin-left: 9vw;
        margin-top: 200px;
    }
}

@media only screen and (min-width: 1111px) and (max-width: 1400px) {
    .searchStyleAssignedCases {
        width: 300%;
        margin-left: 15.4vw;
        margin-top: 140px;
    }
}

@media only screen and (min-width: 600px) and (max-width: 1000px) {
    .searchStyleAssignedCases {
        width: 1700%;
        margin-left: -13vw;
        margin-top: -35px;
    }
}

@media only screen and (min-width: 1001px) and (max-width: 1110px) {
    .searchStyleAssignedCases {
        width: 1200%;
        margin-left: -6.5vw;
        margin-top: -20px;
    }
}

@media only screen and (min-width: 1281px) and (max-width: 1400px) {
    .searchStyleAssignedCases {
        width: 950%;
        margin-left: 4.2vw;
        margin-top: 42px;
    }
}

@media only screen and (min-width: 1111px) and (max-width: 1280px) {
    .searchStyleAssignedCases {
        width: 250%;
        margin-left: -6vw;
        margin-top: 110px;
    }
}

@media screen and (min-width: 1801px) and (max-width: 2600px) {
    .searchStyleAssignedCases {
        width: 750%;
        margin-left: 14.5vw;
        margin-top: 27px;
    }
}

@media screen and (min-width: 2601px) and (max-width: 4600px) {
    .searchStyleAssignedCases {
        width: 150%;
        margin-left: 17.5vw;
        margin-top: 230px;
    }
}

.searchStyleResolvedCases {
    margin-top: -20px;
    cursor: pointer;
    margin-left: 31.5vw;
    width: 100%;
    margin-bottom: -25px;
    transition: filter 0.3s ease;
}


.headerStyleMyOpenCases {
    margin-left: 5.55vw;
    margin-bottom: -140px;
    margin-top: -10px;
    margin-right: 20px;
}

@media only screen and (min-width: 100px) and (max-width: 599px) {
    .headerStyleMyOpenCases {
        /* width: 810%;
        margin-left: -14vw;
        margin-top: 116px;
        margin-bottom: -18px; */
    }
}

@media screen and (min-width: 1601px) and (max-width: 1800px) {
    .headerStyleMyOpenCases {
        /* width: 220%;
        margin-left: 24vw;
        margin-top: 192px; */
    }
}

@media screen and (min-width: 1401px) and (max-width: 1600px) {
    .headerStyleMyOpenCases {
        margin-left: 5.55vw;
        margin-bottom: -140px;
        margin-top: -10px;
        margin-right: -54px;
    }
}

@media only screen and (min-width: 1111px) and (max-width: 1400px) {
    .headerStyleMyOpenCases {
        /* width: 300%;
        margin-left: 15.4vw;
        margin-top: 140px; */
    }
}

@media only screen and (min-width: 600px) and (max-width: 1000px) {
    .headerStyleMyOpenCases {
        margin-left: 5.55vw;
        /* margin-bottom: -140px; */
        /* margin-top: -10px; */
        /* margin-right: -54px; */
    }
}

@media only screen and (min-width: 1001px) and (max-width: 1110px) {
    .headerStyleMyOpenCases {
        /* width: 500%;
        margin-left: 3vw;
        margin-top: 86px; */
    }
}

@media screen and (min-width: 1801px) and (max-width: 2600px) {
    .headerStyleMyOpenCases {
        /* width: 320%;
        margin-left: 24vw;
        margin-top: 170px;     */
    }
}

@media screen and (min-width: 2601px) and (max-width: 4600px) {
    .headerStyleMyOpenCases {
        /* width: 150%;
        margin-left: 17.5vw;
        margin-top: 230px;  */
    }
}

.paginationMyOpenCases {
    margin: -40px 0 0 0px;
    display: flex;
    font-size: 14px;
    color: #fff;
}

@media only screen and (min-width: 600px) and (max-width: 1000px) {
    .paginationMyOpenCases {
        margin-top: -30px;
        margin-left: 0px;
        display: flex;
    }
}

@media only screen and (min-width: 1001px) and (max-width: 1110px) {
    .paginationMyOpenCases {
        margin-top: -20px;
        margin-left: -110px;
        display: flex;
    }
}

@media screen and (min-width: 1401px) and (max-width: 1600px) {
    .paginationMyOpenCases {
        margin-top: 5px;
        margin-left: -100px;
        /* font-size: 20px; */
        display: flex;
    }
}

@media screen and (min-width: 1601px) and (max-width: 1800px) {
    .paginationMyOpenCases {
        margin-top: 5px;
        margin-left: -100px;
        /* font-size: 20px; */
        display: flex;
    }
}

@media only screen and (min-width: 1276px) and (max-width: 1400px) {
    .paginationMyOpenCases {
        margin: -170px 110px 0 0;
    }
}

@media only screen and (min-width: 1111px) and (max-width: 1275px) {
    .paginationMyOpenCases {
        margin: -270px 0px 0 -98px;

    }
}

@media screen and (min-width: 1801px) and (max-width: 2500px) {
    .paginationMyOpenCases {
        margin-top: 5px;
        margin-left: -100px;
        /* font-size: 20px; */
        display: flex;
    }
}

.paginationUnassignedCases {
    margin: 20px 10px 0 0px;
    display: flex;
}

@media only screen and (min-width: 600px) and (max-width: 1000px) {
    .paginationUnassignedCases {
        margin-top: 30px;
        margin-left: -70px;
        display: flex;
    }
}

@media only screen and (min-width: 1026px) and (max-width: 1110px) {
    .paginationUnassignedCases {
        margin-top: 30px;
        margin-left: -72px;
        display: flex;
    }
}

@media only screen and (min-width: 1001px) and (max-width: 1025px) {
    .paginationUnassignedCases {
        margin-top: 30px;
        margin-left: -65px;
        display: flex;
    }
}

@media screen and (min-width: 1601px) and (max-width: 1800px) {
    .paginationUnassignedCases {
        margin-top: 5px;
        margin-left: 35px;
        display: flex;
    }
}

@media screen and (min-width: 1451px) and (max-width: 1600px) {
    .paginationUnassignedCases {
        margin-top: 5px;
        margin-left: 9px;
        display: flex;
    }
}

@media screen and (min-width: 1401px) and (max-width: 1450px) {
    .paginationUnassignedCases {
        margin-top: 20px;
        margin-left: 1px;
        display: flex;
    }
}

@media only screen and (min-width: 1276px) and (max-width: 1400px) {
    .paginationUnassignedCases {
        margin: 70px 10px 0 -5px;
    }
}

@media only screen and (min-width: 1111px) and (max-width: 1275px) {
    .paginationUnassignedCases {
        margin-top: 30px;
        margin-left: -6px;
        display: flex;
    }
}

@media screen and (min-width: 1801px) and (max-width: 2600px) {
    .paginationUnassignedCases {
        margin-top: 550px;
        margin-left: -84vw;
        display: flex;
    }
}


.paginationAssignedCases {
    margin: 20px 10px 0 0px;
    display: flex;
}

@media only screen and (min-width: 600px) and (max-width: 770px) {
    .paginationAssignedCases {
        margin-top: 30px;
        margin-left: -59px;
        display: flex;
    }
}

@media only screen and (min-width: 771px) and (max-width: 1000px) {
    .paginationAssignedCases {
        margin-top: 30px;
        margin-left: -57px;
        display: flex;
    }
}

@media only screen and (min-width: 1001px) and (max-width: 1110px) {
    .paginationAssignedCases {
        margin-top: 30px;
        margin-left: -57px;
        display: flex;
    }
}

@media screen and (min-width: 1601px) and (max-width: 1800px) {
    .paginationAssignedCases {
        margin-top: 5px;
        margin-left: 9px;
        display: flex;
    }
}

@media screen and (min-width: 1451px) and (max-width: 1600px) {
    .paginationAssignedCases {
        margin-top: 40px;
        margin-left: -10px;
        display: flex;
    }
}

@media screen and (min-width: 1401px) and (max-width: 1450px) {
    .paginationAssignedCases {
        margin-top: 20px;
        margin-left: -12px;
        display: flex;
    }
}

@media only screen and (min-width: 1276px) and (max-width: 1400px) {
    .paginationAssignedCases {
        margin: 30px 10px 0 -15px;
    }
}

@media only screen and (min-width: 1111px) and (max-width: 1275px) {
    .paginationAssignedCases {
        margin-top: 20px;
        margin-left: -16px;
        display: flex;
    }
}

@media screen and (min-width: 1801px) and (max-width: 2600px) {
    .paginationAssignedCases {
        /* margin-top: 550px; */
        /* margin-left: 42.2vw;
        display: flex; */

        margin-top: 5px;
        margin-left: 15px;
        display: flex;
    }
}

.paginationResolvedCases {
    margin: 20px 10px 0 0px;
    display: flex;
}

@media only screen and (min-width: 600px) and (max-width: 770px) {
    .paginationResolvedCases {
        margin-top: 30px;
        margin-left: -59px;
        display: flex;
    }
}

@media only screen and (min-width: 771px) and (max-width: 1000px) {
    .paginationResolvedCases {
        margin-top: 30px;
        margin-left: -57px;
        display: flex;
    }
}

@media only screen and (min-width: 1001px) and (max-width: 1110px) {
    .paginationResolvedCases {
        margin-top: 30px;
        margin-left: -57px;
        display: flex;
    }
}

@media screen and (min-width: 1601px) and (max-width: 1800px) {
    .paginationResolvedCases {
        margin-top: 5px;
        margin-left: 9px;
        display: flex;
    }
}

@media screen and (min-width: 1451px) and (max-width: 1600px) {
    .paginationResolvedCases {
        margin-top: 15px;
        margin-left: -12px;
        display: flex;
    }
}

@media screen and (min-width: 1401px) and (max-width: 1450px) {
    .paginationResolvedCases {
        margin-top: 20px;
        margin-left: -13px;
        display: flex;
    }
}

@media only screen and (min-width: 1276px) and (max-width: 1400px) {
    .paginationResolvedCases {
        margin: 30px 10px 0 -15px;
    }
}

@media only screen and (min-width: 1111px) and (max-width: 1275px) {
    .paginationResolvedCases {
        margin-top: 20px;
        margin-left: -16px;
        display: flex;
    }
}

@media screen and (min-width: 1801px) and (max-width: 2600px) {
    .paginationResolvedCases {
        /* margin-top: 550px; */
        /* margin-left: 42.2vw;
        display: flex; */

        margin-top: 5px;
        margin-left: 30px;
        display: flex;
    }
}

.downloadSelectStyle {
    margin-top: 5px;
}

@media only screen and (min-width: 600px) and (max-width: 1000px) {
    .downloadSelectStyle {
        /* margin-top: 5px; */
    }
}

@media only screen and (min-width: 1001px) and (max-width: 1110px) {
    .downloadSelectStyle {
        margin-top: 5px;
    }
}

@media only screen and (min-width: 1111px) and (max-width: 1400px) {
    .downloadSelectStyle {
        margin-top: 30px;
        margin-left: -35px;
    }
}

@media only screen and (min-width: 100px) and (max-width: 599px) {
    .downloadSelectStyle {
        margin-top: 5px;
    }
}

@media screen and (min-width: 1401px) and (max-width: 1600px) {
    .downloadSelectStyle {
        margin-top: 5px;
    }
}

@media screen and (min-width: 1601px) and (max-width: 1800px) {
    .downloadSelectStyle {
        margin-top: 5px;
    }
}

@media screen and (min-width: 1801px) and (max-width: 2600px) {
    .downloadSelectStyle {
        margin-top: -58px;
        margin-left: -112px;
    }
}

@media screen and (min-width: 2601px) and (max-width: 4600px) {
    .downloadSelectStyle {
        margin-top: 5px;
    }
}


.downloadSelectUnassigned {
    margin-top: -115px;
    margin-left: -31px;
}

@media only screen and (min-width: 600px) and (max-width: 1000px) {
    .downloadSelectUnassigned {
        margin-top: -115px;
        margin-left: -100px;
    }
}

@media only screen and (min-width: 1001px) and (max-width: 1025px) {
    .downloadSelectUnassigned {
        margin-top: 82px;
        margin-left: -120px;
    }
}


@media only screen and (min-width: 1026px) and (max-width: 1110px) {
    .downloadSelectUnassigned {
        margin-top: -120px;
        margin-left: -120px;
    }
}
@media only screen and (min-width: 1111px) and (max-width: 1275px) {
    .downloadSelectUnassigned {
        margin-top: -40px;
        margin-left: -210px;
    }
}

@media only screen and (min-width: 1276px) and (max-width: 1400px) {
    .downloadSelectUnassigned {
        margin-top: -105px;
        margin-left: -204px;
        margin-bottom: 103px;
    }
}

@media only screen and (min-width: 100px) and (max-width: 599px) {
    .downloadSelectUnassigned {
        margin-top: 5px;
    }
}

@media screen and (min-width: 1401px) and (max-width: 1500px) {
    .downloadSelectUnassigned {
        margin-top: 88px;
        margin-left: -185px;
        margin-bottom: 80px;
    }
}

@media screen and (min-width: 1451px) and (max-width: 1600px) {
    .downloadSelectUnassigned {
        margin-top: 40px;
        margin-left: -185px;
        margin-bottom: 80px;
    }
}

@media screen and (min-width: 1601px) and (max-width: 1800px) {
    .downloadSelectUnassigned {
        /* margin-top: 5px; */
    }
}

@media screen and (min-width: 1801px) and (max-width: 2600px) {
    .downloadSelectUnassigned {
        margin-top: -138px;
        margin-left: -144px;
        margin-bottom: 130px;
    }
}

@media screen and (min-width: 2601px) and (max-width: 4600px) {
    .downloadSelectUnassigned {
        margin-top: -138px;
        margin-left: -144px;
    }
}

.downloadSelectAssigned {
    margin-top: -115px;
    margin-left: -31px;
}

@media only screen and (min-width: 600px) and (max-width: 770px) {
    .downloadSelectAssigned {
        margin-top: -115px;
        margin-left: -140px;
    }
}

@media only screen and (min-width: 771px) and (max-width: 1000px) {
    .downloadSelectAssigned {
        margin-top: -115px;
        margin-left: -120px;
    }
}

@media only screen and (min-width: 1001px) and (max-width: 1025px) {
    .downloadSelectAssigned {
        margin-top: 87px;
        margin-left: -120px;
    }
}

@media only screen and (min-width: 1026px) and (max-width: 1110px) {
    .downloadSelectAssigned {
        margin-top: -117px;
        margin-left: -120px;
    }
}

@media only screen and (min-width: 1111px) and (max-width: 1275px) {
    .downloadSelectAssigned {
        margin-top: 10px;
        margin-left: -210px;
    }
}

@media only screen and (min-width: 1276px) and (max-width: 1400px) {
    .downloadSelectAssigned {
        margin-top: -95px;
        margin-left: -208px;
        margin-bottom: 103px;
    }
}

@media only screen and (min-width: 100px) and (max-width: 599px) {
    .downloadSelectAssigned {
        margin-top: 5px;
    }
}

@media screen and (min-width: 1401px) and (max-width: 1445px) {
    .downloadSelectAssigned {
        margin-top: 85px;
        margin-left: -185px;
        margin-bottom: 85px;
    }
}

@media screen and (min-width: 1451px) and (max-width: 1600px) {
    .downloadSelectAssigned {
        margin-top: 40px;
        margin-left: -185px;
        margin-bottom: 80px;
    }
}

@media screen and (min-width: 1601px) and (max-width: 1800px) {
    .downloadSelectAssigned {
        /* margin-top: 5px; */
    }
}

@media screen and (min-width: 1801px) and (max-width: 2600px) {
    .downloadSelectAssigned {
        margin-top: -157px;
        margin-left: -144px;
        margin-bottom: 130px;
    }
}

@media screen and (min-width: 2601px) and (max-width: 4600px) {
    .downloadSelectAssigned {
        margin-top: -138px;
        margin-left: -144px;
    }
}