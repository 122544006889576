//
// Basic Bootstrap table
//

.table {
  width: 100%;
  margin-bottom: $spacer;
  color: $table-color;
  background-color: $table-bg; // Reset for nesting within parents with `background-color`.

  th,
  td {
    padding: $table-cell-padding;
    vertical-align: top;
    // border-top: $table-border-width solid $table-border-color;
  }

  thead th {
    // vertical-align: bottom;
    // border-bottom: (2 * $table-border-width) solid $table-border-color;
  }

  tbody+tbody {
    border-top: (2 * $table-border-width) solid $table-border-color;
  }
}


//
// Condensed table w/ half padding
//

.table-sm {

  th,
  td {
    padding: $table-cell-padding-sm;
  }
}


// Border versions
//
// Add or remove borders all around the table and between all the columns.

.table-bordered {
  border: $table-border-width solid $table-border-color;

  th,
  td {
    border: $table-border-width solid $table-border-color;
  }

  thead {

    th,
    td {
      border-bottom-width: 2 * $table-border-width;
    }
  }
}

.table-borderless {

  th,
  td,
  thead th,
  tbody+tbody {
    border: 0;
  }
}

// Zebra-striping
//
// Default zebra-stripe styles (alternating gray and transparent backgrounds)

.table-striped {
  tbody tr:nth-of-type(#{$table-striped-order}) {
    background-color: $table-accent-bg;
  }
}


// Hover effect
//
// Placed here since it has to come after the potential zebra striping

.table-hover {
  tbody tr {
    @include hover {
      color: $table-hover-color;
      background-color: $table-hover-bg;
    }
  }
}


// Table backgrounds
//
// Exact selectors below required to override `.table-striped` and prevent
// inheritance to nested tables.

@each $color,
$value in $theme-colors {
  @include table-row-variant($color, theme-color-level($color, $table-bg-level), theme-color-level($color, $table-border-level));
}

@include table-row-variant(active, $table-active-bg);


// Dark styles
//
// Same table markup, but inverted color scheme: dark background and light text.

// stylelint-disable-next-line no-duplicate-selectors
.table {
  .thead-dark {
    th {
      color: $table-dark-color;
      background-color: $table-dark-bg;
      border-color: $table-dark-border-color;
    }
  }

  .thead-light {
    th {
      color: $table-head-color;
      background-color: $table-head-bg;
      border-color: $table-border-color;
    }
  }
}

.table-dark {
  color: $table-dark-color;
  background-color: $table-dark-bg;

  th,
  td,
  thead th {
    // border-color: $table-dark-border-color;
  }

  &.table-bordered {
    border: 0;
  }

  &.table-striped {
    tbody tr:nth-of-type(odd) {
      background-color: $table-dark-accent-bg;
    }
  }

  &.table-hover {
    tbody tr {
      @include hover {
        color: $table-dark-hover-color;
        background-color: $table-dark-hover-bg;
      }
    }
  }
}


// Responsive tables
//
// Generate series of `.table-responsive-*` classes for configuring the screen
// size of where your table will overflow.

.table-responsive {
  @each $breakpoint in map-keys($grid-breakpoints) {
    $next: breakpoint-next($breakpoint, $grid-breakpoints);
    $infix: breakpoint-infix($next, $grid-breakpoints);

    &#{$infix} {
      @include media-breakpoint-down($breakpoint) {
        display: block;
        width: 100%;
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;

        // Prevent double border on horizontal scroll due to use of `display: block;`
        >.table-bordered {
          border: 0;
        }
      }
    }
  }
}



//..............................EasyDev Table Styles.................................//@at-root


.table {
  overflow-x: auto;
  overflow-y: auto;

  &::-webkit-scrollbar {
    height: 6px;
    width: 6px;
    opacity: 0.3;
    background-color: #101010;

  }

  &::-webkit-scrollbar-thumb {
    border-radius: 100px;
    border: 1px solid transparent;
    background-color: #000;
  }

  //     ::-webkit-scrollbar {
  //     width: 5px;
  // }

  // /* Track */
  // ::-webkit-scrollbar-track {
  //     box-shadow: inset 0 0 5px grey;
  //     border-radius: 10px;
  // }

  // /* Handle */
  // ::-webkit-scrollbar-thumb {
  //     background: #000000;
  // }

  //     /*     Handle on hover */
  //     ::-webkit-scrollbar-thumb:hover {
  //         background: #373737;
  //     }

  // &::-webkit-scrollbar {
  //   height: 6px;
  //   width: 6px;
  //   opacity: 0.3;
  //   background-color: rgba(19, 19, 19, 0.1);

  // }

  // &::-webkit-scrollbar-thumb {
  //   border-radius: 100px;
  //   border: none;
  //   background-color: rgba(19, 19, 19, 0.1);
  // }

  @include directify($directions) {
    text-align: directed('left');
  }

  &:last-child {
    margin-bottom: 0;
  }

  table {
    width: inherit;

  }

  thead tr {
    // position: sticky;
    top: 0;
  }

  thead th {
    min-height: 45px;
    min-width: 100px;
    padding: 10px 0 10px 0;
    top: 0;
    // position: sticky;

    &:first-child {
      min-width: 45px;
    }

    // @include themify($themes) {
    //   color: themed('colorText');
    //   border-bottom-color: themed('colorBorder');
    //   background: themed('colorBackground');
    // }
  }

  tbody td,
  th {
    border: none;
    padding: 10px 0 10px 0;

    // @include themify($themes) {
    //   color: themed('colorTextAdditional');
    // }
  }

  th,
  td {

    &:first-child {
      padding-left: 10px;
    }

  }

  .badge {
    font-size: 100%;
  }

  tfoot td {
    min-height: 45px;
    min-width: 100px;
    padding: 10px 0 10px 0;
    font-family: 'Roboto', sans-serif;
    color: $color-text;
    font-weight: 700;

    &:first-child {
      min-width: 45px;
      white-space: nowrap
    }
  }

  &.table--not-pagination {
    // height: 580px;
    height: 650px;

    tbody {
      top: 30px;
      // background-color: red;
    }
  }

  &.table--not-pagination1 {
    height: 652px;
    // height: 70vh;

    tbody {
      top: 30px;
      // background-color: red;

      // border-bottom-left-radius: 15px;
      // align-items: right;
    }

  }

  &.table--not-pagination2 {
    height: 417px;
    // height: 50.7vh;

    tbody {
      top: 30px;
    }
  }

  &.table--not-pagination3 {
    height: 600px;
    // height: 70vh;

    tbody {
      top: 30px;
    }
  }

  &.table--bordered {

    @include directify($directions) {
      text-align: directed('left');
    }

    tr {
      // border-bottom: 1px solid #212121; //333246

      // border-bottom: 5px solid #000000;
      // border-bottom-left-radius: 15px;
      // background-color: linear-gradient(97deg, rgba(10,10,10,1) 0%, rgba(37,37,37,1) 99%);

      &:first-child {
        min-width: 45px;
      }


      &:last-child {
        border-bottom-color: 1px solid #212121;
      }
    }
  }

  &.table--head-accent {

    thead {

      @include themify($themes) {
        color: themed('colorTextAdditional');
        background-color: themed('colorHover');
      }

      th {
        border-bottom: none;
      }
    }
  }

  &.table-striped {

    tbody tr:nth-of-type(odd) {

      @include themify($themes) {
        background-color: themed('colorHover');
      }
    }
  }

  // &.table-hover {

  //   tbody tr {
  //     transition: all 0.3s;
  //     cursor: pointer;

  //     &:hover {
  //       background-color: 'colorBackgroundBody';

  //       @include themify($themes) {
  //         background-color: themed('colorBackgroundBody');
  //       }
  //     }
  //   }
  // }

  .react-table {

    thead tr {
      position: sticky;
      top: 0;
      background: rgba(0, 0, 0, 0.90);
      z-index: 30;

    }
  }

  .tr-hover {
    &:hover {
      transition: "all ease .5s";
      background-color: #222222;
    }
  }

  .sticky {
    overflow: scroll;

    .header,
    .footer {
      position: sticky;
      z-index: 5;
      width: fit-content;
    }

    // .tr-hover {
    //   &:hover {
    //     transition: "all ease .5s";
    //     background-color:#2c2c2c;
    //   }
    // }

    .footer {
      bottom: 0;
      box-shadow: 0px -3px 3px #ccc;
    }

    .body {
      position: relative;

    }

    [data-sticky-td] {
      position: sticky;
      background: rgba(0, 0, 0, .90);

      // .tr-hover {
      // &:hover {
      //   transition: "all ease .5s";
      //   background-color:#2c2c2c;
      // }
      // }
      // z-index: 300;

    }

    // [data-sticky-last-left-td] {
    //   box-shadow: 2px 0px 3px #ccc;
    // }

    // [data-sticky-first-right-td] {
    //   box-shadow: -2px 0px 3px #ccc;
    // }
    // .tr-hover {
    //   &:hover {
    //     transition: "all ease .5s";
    //     background-color:#2c2c2c;
    //   }
    // }
  }



  // .checkbox {
  //   display: inline-flex;
  //   cursor: pointer;
  //   position: relative;
  // }

  // .checkbox>span {
  //   color: #34495E;
  //   padding: 0.5rem 0.25rem;
  // }

  // .checkbox>input {
  //   height: 15px;
  //   width: 15px;
  //   -webkit-appearance: none;
  //   -moz-appearance: none;
  //   -o-appearance: none;
  //   appearance: none;
  //   border: 1px solid #fff;
  //   // border-radius: 20px;
  //   outline: none;
  //   transition-duration: 0.3s;
  //   // background-color: #000;
  //   cursor: pointer;
  // }

  // .checkbox>input:checked {
  //   background-color: #1EF1C6;
  //   border: 2px solid #000;
  // }

  // .checkbox>input:checked+span::before {
  //   content: '\2713';
  //   display: block;
  //   text-align: center;
  //   color: #41B883;
  //   position: absolute;
  //   left: 0.7rem;
  //   top: 0.2rem;
  // }

  // .checkbox>input:active {
  //   border: 2px solid #34495E;
  // }


  // // .tr-hover {
  // //   &:hover {
  // //     transition: "all ease .5s";
  // //     background-color:#2c2c2c;
  // //   }

  // // }

  .tr-selection {
    background-color: #2c2c2c;
    color: white;
  }



  /////////////////////////
  .td1 {

    // &:first-child {
    //   padding-left: 10px;
    // }

    // To grey out Odometer, RV Curve and RV Range

    // &:nth-child(9) {
    //   padding-left: 10px;
    //   color: #2C2C2C;

    // }
    // &:nth-child(15) {
    //   padding-left: 10px;
    //   color: #2C2C2C;

    // }
    // &:nth-child(16) {
    //   padding-left: 10px;
    //   color: #2C2C2C;

    // }

    &:last-child {
      padding-right: 10px;
    }
  }

  .lynkcoRed {
    color: #DC1860;
  }

  /////////////////////////


  .checkbox {
    display: inline-flex;
    cursor: pointer;
    position: relative;
    background-color: #000;

  }

  .checkbox>span {
    color: #34495E;
    padding: 0.5rem 0.25rem;
    background-color: #000;
  }


  .checkbox>input {
    height: 20px;
    width: 20px;
    // -webkit-appearance: none;
    // -moz-appearance: none;
    // -o-appearance: none;
    // appearance: none;
    accent-color: #1EF1C6;
    border: 1px solid #000;
    border-radius: 1px;
    outline: none;
    transition-duration: 0.3s;
    background-color: rgba(255, 255, 255, 0.1);
    cursor: pointer;
  }

  .checkbox>input:hover {
    accent-color: #088a70;
  }

  .checkbox>input:checked:hover {
    background-color: #088a70;

  }

  .checkbox>input:checked {
    background-color: #1EF1C6;
    border: solid #000;
  }

  .checkbox>input:checked+span::before {
    content: '\2713';
    display: block;
    text-align: center;
    color: #41B883;
    position: absolute;
    left: 0.7rem;
    top: 0.2rem;
    border: 2px solid #000;
    accent-color: #088a70;
  }

  .checkbox>input:active {
    border: 2px solid #34495E;

  }

  ///
  .checkboxVehiclePage {
    display: inline-flex;
    cursor: pointer;
    position: relative;
    margin-left: 40px;
  }

  .checkboxVehiclePage>span {
    color: #34495E;
    padding: 0.5rem 0.25rem;
    background-color: #000;

  }


  .checkboxVehiclePage>input {
    height: 20px;
    width: 20px;
    // -webkit-appearance: none;
    // -moz-appearance: none;
    // -o-appearance: none;
    // appearance: none;
    accent-color: #1EF1C6;
    border: 1px solid #000;
    border-radius: 1px;
    outline: none;
    transition-duration: 0.3s;
    background-color: rgba(255, 255, 255, 0.1);
    cursor: pointer;
  }

  .checkcheckboxVehiclePagebox>input:hover {
    background-color: #088a70;
  }

  .checkboxVehiclePage>input:checked:hover {
    background-color: #088a70;

  }

  .checkboxVehiclePage>input:checked {
    background-color: #1EF1C6;
    border: solid #000;
  }

  .checkboxVehiclePage>input:checked+span::before {
    content: '\2713';
    display: block;
    text-align: center;
    color: #41B883;
    position: absolute;
    left: 0.7rem;
    top: 0.2rem;
    border: 2px solid #000;
    accent-color: #088a70;
  }

  .checkboxVehiclePage>input:active {
    border: 2px solid #34495E;
  }


  .react-table__column-header {
    display: flex;
    flex-wrap: wrap;
    align-content: center;

    &:first-child {
      margin-right: 10px;
    }

    &.sortable {
      cursor: pointer;

      svg {
        width: 16px;
        height: 16px;

        @include directify($directions) {
          #{directed('right')}: -20px;
        }
      }
    }
  }

  .th {
    min-width: 250px !important;

    &:first-child {
      max-width: 150px;
      min-width: 135px !important;

    }

  }


  .resizable-table {

    thead {
      min-width: 100px;
      overflow-y: auto;
      overflow-x: hidden;

      &:first-child {
        min-width: 45px;
      }
    }

    tbody {
      overflow-y: scroll;
      overflow-x: hidden;
    }

    th,
    td {
      min-width: 250px !important;

      &:first-child {
        max-width: 150px;
        min-width: 135px !important;
      }

      &:nth-child(2) {
        max-width: 370px;
        min-width: 350px !important;
      }

      .resizer {
        top: 5px;
        right: 10px;
        width: 4px;
        height: 70%;
        position: absolute;
        touch-action: none;

        @include themify($themes) {
          background: themed('colorBorder');
        }

        &.isResizing {

          @include themify($themes) {
            background: themed('colorHeaderDataPicker');
          }
        }
      }
    }
  }

  // .resizable-connectivityCases {

  //   thead {
  //     min-width: 100px;
  //     overflow-y: auto;
  //     overflow-x: hidden;

  //     &:first-child {
  //       min-width: 45px;

  //     }
  //   }

  //   tbody {
  //     overflow-y: scroll;
  //     overflow-x: hidden;
  //   }

  //   th,
  //   td {
  //     min-width: 250px !important;

  //     &:first-child {
  //       max-width: 270px;
  //       min-width: 220px !important;
  //     }

  //     &:nth-child(2) {
  //       max-width: 130px;
  //       min-width: 90px !important;
  //     }

  //     &:nth-child(3) {
  //       max-width: 270px;
  //       min-width: 150px !important;
  //     }

  //     .resizer {
  //       top: 5px;
  //       right: 10px;
  //       width: 4px;
  //       height: 70%;
  //       position: absolute;
  //       touch-action: none;

  //       @include themify($themes) {
  //         background: themed('colorBorder');
  //       }

  //       &.isResizing {

  //         @include themify($themes) {
  //           background: themed('colorHeaderDataPicker');
  //         }
  //       }
  //     }
  //   }
  // }

  tr {
    border-bottom: 4px solid rgba(16, 16, 16, .30);
    padding-left: 20px;
    white-space: nowrap;
    // background-image: linear-gradient((97deg, #0A0A0A 10%, #1B1B1B 99%));
    background-color: transparent;
    border-radius: 4px;
  }

  .resizable-tableVehiclePage {

    // thead {
    //   min-width: 100px;
    //   overflow-y: auto;
    //   overflow-x: hidden;


    //   &:first-child {
    //     min-width: 45px;

    //   }
    // }

    // tbody {
    //   overflow-y: scroll;
    //   overflow-x: hidden;
    // }

    // tbody tr:hover

    // th,
    // td {
    //   // min-width: 200px !important;
    //   // border-bottom: 5px solid #ce3a3a !important;
    //   // padding-left: 20px;
    //   // white-space: nowrap;
    //   // background-image: linear-gradient(97deg, rgba(10,10,10,1) 0%, rgba(37,37,37,1) 99%);

    //   // &:first-child {
    //   //   max-width: 220px;
    //   //   min-width: 110px !important;
    //   //   border-bottom-left-radius: 20px;
    //   //   // border-bottom: 5px solid #000000;


    //   // }



    //   // &:nth-child(2) {
    //   //   max-width: 300px;
    //   //   min-width: 270px !important;
    //   // }

    //   // &:nth-child(6) {
    //   //   max-width: 300px;
    //   //   min-width: 270px !important;
    //   // }


    //   .resizer {
    //     top: 5px;
    //     right: 10px;
    //     width: 4px;
    //     height: 70%;
    //     position: absolute;
    //     touch-action: none;

    //     @include themify($themes) {
    //       background: themed('colorBorder');
    //     }

    //     &.isResizing {

    //       @include themify($themes) {
    //         background: themed('colorHeaderDataPicker');
    //       }
    //     }
    //   }
    // }

    tr {
      border-bottom: 5px solid #000000;
      padding-left: 20px;
      white-space: nowrap;
      background-image: linear-gradient((97deg, #0A0A0A 10%, rgba(37, 37, 37, 1) 99%));

      // (97deg, rgba(10,10,10,1) 0%, rgba(37,37,37,1) 99%)

      border-bottom-left-radius: 20px;
    }
  }

  // tr:not(:first-child):hover {
  //   background-image: linear-gradient(97deg, #212121, rgb(67, 65, 65) 99%);
  //   color: #1EF1C6;
  // }

  // .resizable-tableVehiclePage {


  //   tr {
  //     border-bottom: 5px solid #000000;
  //     padding-left: 20px;
  //     white-space: nowrap;
  //     background-image: linear-gradient(97deg, rgb(9, 8, 8) 0%, rgb(67, 65, 65) 99%);
  //     border-bottom-left-radius: 20px;
  // }

  // tr:hover {
  //   // &:hover {
  //     transition: "all ease .5s";
  //     background-color: red;
  //   // }
  // }

  //   // thead {
  //   //   min-width: 100px;
  //   //   overflow-y: auto;
  //   //   overflow-x: hidden;

  //   //   &:first-child {
  //   //     min-width: 45px;

  //   //   }
  //   // }

  //   // tbody {
  //   //   overflow-y: scroll;
  //   //   overflow-x: hidden;
  //   // }



  //     // td:first-child {
  //     //     border-bottom-left-radius: 20px;
  //     //     border-bottom: 5px solid #000000;
  //     // }

  // // td {
  // //     background-color: linear-gradient(97deg, rgba(10,10,10,1) 0%, rgba(37,37,37,1) 99%);
  // //     align-items: center;
  // // }

  // }


  .resizable-tableVehiclePageSoldCars {

    thead {
      min-width: 100px;
      overflow-y: auto;
      overflow-x: hidden;

      &:first-child {
        min-width: 45px;

      }
    }

    tbody {
      overflow-y: scroll;
      overflow-x: hidden;
    }

    tr {
      border-bottom: 8px solid #000000;
      padding-left: 20px;
      white-space: nowrap;
      background-image: linear-gradient((97deg, #0A0A0A 10%, #1B1B1B 99%));
      border-radius: 4px;

      // (97deg, rgba(10,10,10,1) 0%, rgba(37,37,37,1) 99%)      border-bottom-left-radius: 10px;
    }

    th,
    td {
      min-width: 200px !important;

      &:first-child {
        max-width: 200px;
        min-width: 170px !important;

      }

      &:nth-child(2) {
        max-width: 280px;
        min-width: 230px !important;
      }

      &:nth-child(3) {
        max-width: 250px;
        min-width: 220px !important;
      }

      // &:nth-child(4) {
      //   max-width: 130px;
      //   min-width: 90px !important;
      // }

      // &:nth-child(5) {
      //   max-width: 140px;
      //   min-width: 100px !important;
      // }

      // &:nth-child(6) {
      //   max-width: 140px;
      //   min-width: 90px !important;
      // }

      // &:nth-child(7) {
      //   max-width: 100px;
      //   min-width: 60px !important;
      // }

      // &:nth-child(8) {
      //   max-width: 100px;
      //   min-width: 60px !important;
      // }

      // &:nth-child(9) {
      //   max-width: 100px;
      //   min-width: 60px !important;
      // }

      // &:nth-child(10) {
      //   max-width: 120px;
      //   min-width: 80px !important;
      // }

      // &:nth-child(11) {
      //   max-width: 120px;
      //   min-width: 80px !important;
      // }

      // &:nth-child(12) {
      //   max-width: 130px;
      //   min-width: 90px !important;
      // }

      // &:nth-child(13) {
      //   max-width: 150px;
      //   min-width: 120px !important;
      // }

      // &:nth-child(14) {
      //   max-width: 150px;
      //   min-width: 120px !important;
      // }

      // &:nth-child(16) {
      //   max-width: 200px;
      //   min-width: 150px !important;
      // }

      // &:nth-child(17) {
      //   max-width: 170px;
      //   min-width: 120px !important;
      // }

      // &:nth-child(20) {
      //   max-width: 170px;
      //   min-width: 120px !important;
      // }

      // &:nth-child(21) {
      //   max-width: 170px;
      //   min-width: 120px !important;
      // }

      // &:nth-child(22) {
      //   max-width: 150px;
      //   min-width: 100px !important;
      // }

      // &:nth-child(23) {
      //   max-width: 150px;
      //   min-width: 100px !important;
      // }

      // &:nth-child(24) {
      //   max-width: 130px;
      //   min-width: 80px !important;
      // }

      // &:nth-child(25) {
      //   max-width: 140px;
      //   min-width: 110px !important;
      // }

      // &:nth-child(26) {
      //   max-width: 130px;
      //   min-width: 100px !important;
      // }

      // &:nth-child(27) {
      //   max-width: 150px;
      //   min-width: 120px !important;
      // }

      .resizer {
        top: 5px;
        right: 10px;
        width: 4px;
        height: 70%;
        position: absolute;
        touch-action: none;

        @include themify($themes) {
          background: themed('colorBorder');
        }

        &.isResizing {

          @include themify($themes) {
            background: themed('colorHeaderDataPicker');
          }
        }
      }
    }
  }

  .resizable-tableConnectivityPage {

    thead {
      min-width: 100px;
      overflow-y: auto;
      overflow-x: auto;

      &:first-child {
        min-width: 45px;

      }
    }

    tbody {
      overflow-y: scroll;
      overflow-x: hidden;
    }

    th,
    td {
      min-width: 250px !important;

      &:first-child {
        max-width: 210px;
        min-width: 190px !important;
      }


      &:nth-child(2) {
        max-width: 220px;
        min-width: 205px !important;
      }

      &:nth-child(4) {
        max-width: 150px;
        min-width: 125px !important;
      }

      &:nth-child(5) {
        max-width: 200px;
        min-width: 175px !important;
      }

      // thead {
      //   min-width: 100px;
      //   overflow-y: auto;
      //   overflow-x: hidden;

      //   &:first-child {
      //     min-width: 45px;
      //   }
      // }

      // // tbody {
      // //   overflow-y: scroll;
      // //   overflow-x: hidden;
      // // }

      // // th,
      // // td {
      // //   min-width: 100px !important;

      // //   &:first-child {
      // //     max-width: 60px;
      // //     min-width: 60px !important;
      // //   }

      //   .resizer {
      //     top: 5px;
      //     right: 10px;
      //     width: 4px;
      //     height: 70%;
      //     position: absolute;
      //     touch-action :none;
      //     background: ${colorBorder};

      //     &.isResizing {
      //       background: ${colorHeaderDataPicker};
      //     }
      //   }
      // }


      .resizer {
        top: 5px;
        right: 10px;
        width: 4px;
        height: 70%;
        position: absolute;
        touch-action: none;

        @include themify($themes) {
          background: themed('colorBorder');
        }

        &.isResizing {

          @include themify($themes) {
            background: themed('colorHeaderDataPicker');
          }
        }
      }
    }
  }

  .resizable-tableRvTable {

    thead {
      min-width: 100px;
      overflow-y: auto;
      overflow-x: hidden;

      &:first-child {
        min-width: 45px;
      }
    }

    tbody {
      overflow-y: scroll;
      overflow-x: hidden;
    }

    tr {
      border-bottom: 4px solid rgba(16, 16, 16, .30);
      padding-left: 20px;
      white-space: nowrap;
      // background-image: linear-gradient((97deg, #0A0A0A 10%, #1B1B1B 99%));
      background-color: transparent;
      border-radius: 4px;
    }

    th,
    td {
      z-index: 0;
      min-width: 20px !important;

      &:first-child {
        max-width: 450px;
        min-width: 45px;
      }


      // &:nth-child(3) {
      //   max-width: 150px;
      //   min-width: 50px !important;
      // }

      // &:nth-child(2) {
      //   max-width: 180px;
      //   min-width: 140px !important;
      // }

      // &:nth-child(3) {
      //   max-width: 150px;
      //   min-width: 80px !important;
      // }

      &:nth-child(5) {
        max-width: 330px;
        min-width: 270px !important;
      }

      &:nth-child(6) {
        max-width: 300px;
        min-width: 270px !important;
      }

      &:nth-child(7) {
        max-width: 250px;
        min-width: 165px !important;
      }

      &:nth-child(9) {
        max-width: 140px;
        min-width: 70px !important;
      }

      &:nth-child(10) {
        max-width: 150px;
        min-width: 70px !important;
      }

      &:nth-child(11) {
        max-width: 120px;
        min-width: 70px !important;
      }

      &:nth-child(12) {
        max-width: 120px;
        min-width: 70px !important;
      }

      &:nth-child(13) {
        max-width: 140px;
        min-width: 70px !important;
      }

      &:nth-child(14) {
        max-width: 140px;
        min-width: 50px !important;
      }

      &:nth-child(15) {
        max-width: 120px;
        min-width: 50px !important;
      }

      &:nth-child(16) {
        max-width: 120px;
        min-width: 50px !important;
      }



      .resizer {
        top: 5px;
        right: 10px;
        width: 4px;
        height: 70%;
        position: absolute;
        touch-action: none;

        @include themify($themes) {
          background: themed('colorBorder');
        }

        &.isResizing {

          @include themify($themes) {
            background: themed('colorHeaderDataPicker');
          }
        }
      }
    }
  }


  .react-dnd--off {
    pointer-events: none;
  }

  .react-dnd-draggable {
    z-index: 98 !important;

    td {
      width: inherit;

      &:first-child {
        width: 70px;
        min-width: 70px !important;
      }
    }
  }

  .editable-table {
    user-select: none;
    /* standard syntax */
    -webkit-user-select: none;
    /* webkit (safari, chrome) browsers */
    -moz-user-select: none;
    /* mozilla browsers */
    -ms-user-select: none;
    /* IE10+ */

    thead {
      min-width: 100px;
      overflow-y: auto;
      overflow-x: hidden;


      &:first-child {
        min-width: 45px;
      }
    }

    thead tr {
      position: sticky;
      top: 0;
      background: rgba(19, 19, 19, 1.90);
      z-index: 300;

    }

    th,
    td {
      min-width: 100px;

      &:first-child {
        max-width: 450px;
        min-width: 45px;
      }

      &:nth-child(5) {
        max-width: 330px;
        min-width: 270px !important;
      }

      &:nth-child(6) {
        max-width: 300px;
        min-width: 270px !important;
      }

      &:nth-child(7) {
        max-width: 250px;
        min-width: 165px !important;
      }

      &:nth-child(9) {
        max-width: 140px;
        min-width: 70px !important;
      }

      &:nth-child(10) {
        max-width: 150px;
        min-width: 70px !important;
      }

      &:nth-child(11) {
        max-width: 120px;
        min-width: 70px !important;
      }

      &:nth-child(12) {
        max-width: 120px;
        min-width: 70px !important;
      }

      &:nth-child(13) {
        max-width: 140px;
        min-width: 70px !important;
      }

      &:nth-child(14) {
        max-width: 140px;
        min-width: 50px !important;
      }

      &:nth-child(15) {
        max-width: 120px;
        min-width: 50px !important;
      }

      &:nth-child(16) {
        max-width: 120px;
        min-width: 50px !important;
      }
    }

    .table__editable-table--cell-active {
      max-width: inherit;
      border: none;
      outline: 2px solid $color-greenlynko;
      margin: -7px 10px -6px 0;
    }

    .table__edit-form {
      max-width: fit-content;
      border: none;
      border-radius: 0;
      background: transparent;
      font-size: 13px;
      font-family: 'Roboto', sans-serif;
      padding-left: 0;

      @include themify($themes) {
        background-color: themed('colorBackground');
        color: themed('colorTextAdditional');
      }

      &:active {
        border: none;
        outline: 1px solid $color-blue;
      }
    }

    .form-control {

      &:focus {
        box-shadow: none;
      }
    }
  }
}

.tableConnectivity {
  overflow-x: auto;
  overflow-y: auto;

  table {
    width: 100%;
    margin-bottom: $spacer;
    color: $table-color;
    background-color: $table-bg; // Reset for nesting within parents with `background-color`.

    th,
    td {
      padding: $table-cell-padding;
      vertical-align: top;
      // border-top: $table-border-width solid $table-border-color;
    }

    thead th {
      // vertical-align: bottom;
      // border-bottom: (2 * $table-border-width) solid $table-border-color;
    }

    tbody+tbody {
      border-top: (2 * $table-border-width) solid $table-border-color;
    }
  }

  // &::-webkit-scrollbar {
  //   height: 1px;
  //   width: 0.1px;
  //   opacity: 0.3;
  //   background-color: rgba(19, 19, 19, 0.1);

  // }

  // &::-webkit-scrollbar-thumb {
  //   border-radius: 1px;
  //   border: none;
  //   background-color: rgba(19, 19, 19, 0.1);
  // }

  &::-webkit-scrollbar {
    height: 6px;
    width: 6px;
    opacity: 0.3;
    background-color: #101010;

  }

  &::-webkit-scrollbar-thumb {
    border-radius: 100px;
    border: 1px solid transparent;
    background-color: #000;
  }

  /*     Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: #1EF1C6;
  }

  @include directify($directions) {
    text-align: directed('left');
  }

  &:last-child {
    margin-bottom: 0;
  }

  // table {
  //   width: inherit;
  // }

  thead tr {
    // position: sticky;
    top: 0;
  }

  thead th {
    min-height: 45px;
    min-width: 100px;
    padding: 13px 0 10px 0;
    top: 0;
    // position: sticky;

    &:first-child {
      min-width: 45px;
    }

    // @include themify($themes) {
    //   color: themed('colorText');
    //   border-bottom-color: themed('colorBorder');
    //   background: themed('colorBackground');
    // }
  }

  tbody td,
  th {
    border: none;
    padding: 10px 0 10px 0;

    // @include themify($themes) {
    //   color: themed('colorTextAdditional');
    // }
  }

  th,
  td {
    &:first-child {
      padding-left: 10px;
      
    }

  }

  .badge {
    font-size: 100%;
  }

  tfoot td {
    min-height: 45px;
    min-width: 100px;
    padding: 10px 0 10px 0;
    font-family: 'Roboto', sans-serif;
    color: $color-text;
    font-weight: 700;

    &:first-child {
      min-width: 45px;
      white-space: nowrap
    }
  }

  // &.table--not-pagination {
  //   // height: 580px;
  //   height: 64vh;

  //   tbody {
  //     top: 30px;
  //   }
  // }

  // &.table--not-pagination1 {
  //   // height: 639px;
  //   height: 370px;

  //   tbody {
  //     top: 30px;
  //   }
  // }

  // &.table--not-pagination2 {
  //   // height: 462px;
  //   height: 150.7vh;

  //   tbody {
  //     top: 30px;
  //   }
  // }

  &.table--not-pagination {
    // height: 580px;
    height: 550px;

    tbody {
      top: 30px;
      // background-color: red;
    }
  }

  &.table--not-pagination1 {
    height: 652px;
    // height: 67vh;

    tbody {
      top: 30px;
      // background-color: red;

      // border-bottom-left-radius: 15px;
      // align-items: right;
    }

  }

  &.table--not-pagination2 {
    // height: 417px;
    height: 49vh;

    tbody {
      top: 30px;
    }
  }

  &.table--not-pagination3 {
    height: 635px;
    // height: 67vh;

    tbody {
      top: 30px;
    }
  }

  &.table--bordered {

    @include directify($directions) {
      text-align: directed('left');
    }

    tr {
      border-bottom: 1px solid #212121; //333246




      &:last-child {
        border-bottom-color: 1px solid #212121;
      }
    }
  }

  &.table--head-accent {

    thead {

      @include themify($themes) {
        color: themed('colorTextAdditional');
        background-color: themed('colorHover');
      }

      th {
        border-bottom: none;
      }
    }
  }

  &.table-striped {

    tbody tr:nth-of-type(odd) {

      @include themify($themes) {
        background-color: themed('colorHover');
      }
    }
  }

  &.table-hover {

    tbody tr {
      transition: all 0.3s;
      cursor: pointer;

      &:hover {
        background-color: 'colorBackgroundBody';

        @include themify($themes) {
          background-color: themed('colorBackgroundBody');
        }
      }
    }
  }

  .react-table {

    thead tr {
      position: sticky;
      top: 0;
      background: #212121;
      background: rgba(0, 0, 0, 0.90);
      z-index: 30;
      color: #BFBFBF;
      margin-left: -10px;

    }
  }

  .tr-hover {
    &:hover {
      transition: "all ease .5s";
      background-color: #131313;
    }
  }

  .sticky {
    overflow: scroll;

    .header,
    .footer {
      position: sticky;
      z-index: 5;
      width: fit-content;
    }

    // .tr-hover {
    //   &:hover {
    //     transition: "all ease .5s";
    //     background-color:#2c2c2c;
    //   }
    // }

    .footer {
      bottom: 0;
      box-shadow: 0px -3px 3px #ccc;
    }

    .body {
      position: relative;

    }

    [data-sticky-td] {
      position: sticky;
      background: rgba(19, 19, 19, 1.90);

      // .tr-hover {
      // &:hover {
      //   transition: "all ease .5s";
      //   background-color:#2c2c2c;
      // }
      // }
      // z-index: 300;

    }

    // [data-sticky-last-left-td] {
    //   box-shadow: 2px 0px 3px #ccc;
    // }

    // [data-sticky-first-right-td] {
    //   box-shadow: -2px 0px 3px #ccc;
    // }
    // .tr-hover {
    //   &:hover {
    //     transition: "all ease .5s";
    //     background-color:#2c2c2c;
    //   }
    // }
  }



  // .checkbox {
  //   display: inline-flex;
  //   cursor: pointer;
  //   position: relative;
  // }

  // .checkbox>span {
  //   color: #34495E;
  //   padding: 0.5rem 0.25rem;
  // }

  // .checkbox>input {
  //   height: 15px;
  //   width: 15px;
  //   -webkit-appearance: none;
  //   -moz-appearance: none;
  //   -o-appearance: none;
  //   appearance: none;
  //   border: 1px solid #fff;
  //   // border-radius: 20px;
  //   outline: none;
  //   transition-duration: 0.3s;
  //   // background-color: #000;
  //   cursor: pointer;
  // }

  // .checkbox>input:checked {
  //   background-color: #1EF1C6;
  //   border: 2px solid #000;
  // }

  // .checkbox>input:checked+span::before {
  //   content: '\2713';
  //   display: block;
  //   text-align: center;
  //   color: #41B883;
  //   position: absolute;
  //   left: 0.7rem;
  //   top: 0.2rem;
  // }

  // .checkbox>input:active {
  //   border: 2px solid #34495E;
  // }


  // // .tr-hover {
  // //   &:hover {
  // //     transition: "all ease .5s";
  // //     background-color:#2c2c2c;
  // //   }

  // // }

  .tr-selection {
    background-color: #2c2c2c;
    color: white;
  }



  /////////////////////////
  .td1 {

    // &:first-child {
    //   padding-left: 10px;
    // }

    // To grey out Odometer, RV Curve and RV Range

    // &:nth-child(9) {
    //   padding-left: 10px;
    //   color: #2C2C2C;

    // }
    // &:nth-child(15) {
    //   padding-left: 10px;
    //   color: #2C2C2C;

    // }
    // &:nth-child(16) {
    //   padding-left: 10px;
    //   color: #2C2C2C;

    // }

    &:last-child {
      padding-right: 10px;
    }
  }

  .lynkcoRed {
    color: #DC1860;
  }

  /////////////////////////


  .checkbox {
    display: inline-flex;
    cursor: pointer;
    position: relative;
    background-color: transparent;

  }

  .checkbox>span {
    color: #34495E;
    padding: 0.5rem 0.25rem;
    background-color: #000;
  }


  .checkbox>input {
    height: 20px;
    width: 20px;
    // -webkit-appearance: none;
    // -moz-appearance: none;
    // -o-appearance: none;
    // appearance: none;
    accent-color: #1EF1C6;
    border: 1px solid #000;
    border-radius: 1px;
    outline: none;
    transition-duration: 0.3s;
    background-color: rgba(255, 255, 255, 0.1);
    cursor: pointer;
  }

  .checkbox>input:hover {
    accent-color: #088a70;
  }

  .checkbox>input:checked:hover {
    background-color: #088a70;

  }

  .checkbox>input:checked {
    background-color: #1EF1C6;
    border: solid #000;
  }

  .checkbox>input:checked+span::before {
    content: '\2713';
    display: block;
    text-align: center;
    color: #41B883;
    position: absolute;
    left: 0.7rem;
    top: 0.2rem;
    border: 2px solid #000;
    accent-color: #088a70;
  }

  .checkbox>input:active {
    border: 2px solid #34495E;

  }

  ///
  .checkboxVehiclePage {
    display: inline-flex;
    cursor: pointer;
    position: relative;
    margin-left: 40px;
  }

  .checkboxVehiclePage>span {
    color: #34495E;
    padding: 0.5rem 0.25rem;
    background-color: #000;

  }


  .checkboxVehiclePage>input {
    height: 20px;
    width: 20px;
    // -webkit-appearance: none;
    // -moz-appearance: none;
    // -o-appearance: none;
    // appearance: none;
    accent-color: #1EF1C6;
    border: 1px solid #000;
    border-radius: 1px;
    outline: none;
    transition-duration: 0.3s;
    background-color: rgba(255, 255, 255, 0.1);
    cursor: pointer;
  }

  .checkcheckboxVehiclePagebox>input:hover {
    background-color: #088a70;
  }

  .checkboxVehiclePage>input:checked:hover {
    background-color: #088a70;

  }

  .checkboxVehiclePage>input:checked {
    background-color: #1EF1C6;
    border: solid #000;
  }

  .checkboxVehiclePage>input:checked+span::before {
    content: '\2713';
    display: block;
    text-align: center;
    color: #41B883;
    position: absolute;
    left: 0.7rem;
    top: 0.2rem;
    border: 2px solid #000;
    accent-color: #088a70;
  }

  .checkboxVehiclePage>input:active {
    border: 2px solid #34495E;
  }


  .react-table__column-header {
    display: flex;
    flex-wrap: wrap;
    align-content: center;

    &:first-child {
      margin-right: 10px;
    }

    &.sortable {
      cursor: pointer;

      svg {
        width: 16px;
        height: 16px;

        @include directify($directions) {
          #{directed('right')}: -20px;
        }
      }
    }
  }

  .th {
    min-width: 250px !important;

    &:first-child {
      max-width: 150px;
      min-width: 135px !important;

    }

  }


  .resizable-table {

    thead {
      min-width: 100px;
      overflow-y: auto;
      overflow-x: hidden;

      &:first-child {
        min-width: 45px;

      }
    }

    tbody {
      overflow-y: scroll;
      overflow-x: hidden;
    }

    tr {
      border-bottom: 4px solid rgba(16, 16, 16, .30);
      padding-left: 20px;
      white-space: nowrap;
      // background-image: linear-gradient((97deg, #0A0A0A 10%, #1B1B1B 99%));
      background-color: transparent;
      border-radius: 4px;
    }


    th,
    td {
      min-width: 250px !important;

      &:first-child {
        max-width: 150px;
        min-width: 135px !important;
      }

      &:nth-child(2) {
        max-width: 370px;
        min-width: 350px !important;
      }


      .resizer {
        top: 5px;
        right: 10px;
        width: 4px;
        height: 70%;
        position: absolute;
        touch-action: none;

        @include themify($themes) {
          background: themed('colorBorder');
        }

        &.isResizing {

          @include themify($themes) {
            background: themed('colorHeaderDataPicker');
          }
        }
      }
    }
  }

  .resizable-connectivityCases {

    thead {
      min-width: 100px;
      overflow-y: auto;
      overflow-x: auto;

      &:first-child {
        min-width: 45px;

      }
    }

    tbody {
      overflow-y: scroll;
      overflow-x: scroll;
    }

    tr {
      border-bottom: 4px solid rgba(16, 16, 16, .30);
      padding-left: 1px;
      white-space: nowrap;
      background-color: transparent;
      border-radius: 4px;
    }

    th,
    td {
      min-width: 200px !important;

      &:first-child {
        max-width: 450px;
        min-width: 220px !important;

      }

      &:nth-child(2) {
        max-width: 450px;
        min-width: 130px !important;
      }

      &:nth-child(3) {
        max-width: 450px;
        min-width: 120px !important;
      }

      &:nth-child(4) {
        max-width: 450px;
        min-width: 180px !important;
      }

      .resizer {
        top: 5px;
        right: 10px;
        width: 4px;
        height: 70%;
        position: absolute;
        touch-action: none;

        @include themify($themes) {
          background: themed('colorBorder');
        }

        &.isResizing {

          @include themify($themes) {
            background: themed('colorHeaderDataPicker');
          }
        }
      }
    }
  }

  .resizable-commentsTable {

    thead {
      min-width: 100px;
      overflow-y: auto;
      overflow-x: hidden;

      &:first-child {
        min-width: 45px;

      }
    }

    tbody {
      overflow-y: scroll;
      overflow-x: hidden;
    }

    th,
    td {
      min-width: 250px !important;

      &:first-child {
        max-width: 520px;
        min-width: 450px !important;
      }

      .resizer {
        top: 5px;
        right: 10px;
        width: 4px;
        height: 70%;
        position: absolute;
        touch-action: none;

        @include themify($themes) {
          background: themed('colorBorder');
        }

        &.isResizing {

          @include themify($themes) {
            background: themed('colorHeaderDataPicker');
          }
        }
      }
    }
  }

  .resizable-tableVehiclePage {

    thead {
      min-width: 100px;
      overflow-y: auto;
      overflow-x: hidden;

      &:first-child {
        min-width: 45px;

      }
    }

    tbody {
      overflow-y: scroll;
      overflow-x: hidden;
    }

    tr {
      border-bottom: 4px solid rgba(16, 16, 16, .30);
      padding-left: 20px;
      white-space: nowrap;
      // background-image: linear-gradient((97deg, #0A0A0A 10%, #1B1B1B 99%));
      background-color: transparent;
      border-radius: 4px;
    }

    th,
    td {
      min-width: 200px !important;



      // &:first-child {
      //   max-width: 180px;
      //   min-width: 70px !important;

      // }

      // &:nth-child(2) {
      //   max-width: 280px;
      //   min-width: 230px !important;
      // }

      // &:nth-child(3) {
      //   max-width: 210px;
      //   min-width: 180px !important;
      // }

      // &:nth-child(4) {
      //   max-width: 130px;
      //   min-width: 90px !important;
      // }

      // &:nth-child(5) {
      //   max-width: 140px;
      //   min-width: 100px !important;
      // }

      // &:nth-child(6) {
      //   max-width: 140px;
      //   min-width: 90px !important;
      // }

      // &:nth-child(7) {
      //   max-width: 100px;
      //   min-width: 60px !important;
      // }

      // &:nth-child(8) {
      //   max-width: 100px;
      //   min-width: 60px !important;
      // }

      // &:nth-child(9) {
      //   max-width: 100px;
      //   min-width: 60px !important;
      // }

      // &:nth-child(10) {
      //   max-width: 120px;
      //   min-width: 80px !important;
      // }

      // &:nth-child(11) {
      //   max-width: 120px;
      //   min-width: 80px !important;
      // }

      // &:nth-child(12) {
      //   max-width: 130px;
      //   min-width: 90px !important;
      // }

      // &:nth-child(13) {
      //   max-width: 150px;
      //   min-width: 120px !important;
      // }

      // &:nth-child(14) {
      //   max-width: 150px;
      //   min-width: 120px !important;
      // }

      // &:nth-child(16) {
      //   max-width: 200px;
      //   min-width: 150px !important;
      // }

      // &:nth-child(17) {
      //   max-width: 170px;
      //   min-width: 120px !important;
      // }

      // &:nth-child(20) {
      //   max-width: 170px;
      //   min-width: 120px !important;
      // }

      // &:nth-child(21) {
      //   max-width: 170px;
      //   min-width: 120px !important;
      // }

      // &:nth-child(22) {
      //   max-width: 150px;
      //   min-width: 100px !important;
      // }

      // &:nth-child(23) {
      //   max-width: 150px;
      //   min-width: 100px !important;
      // }

      // &:nth-child(24) {
      //   max-width: 130px;
      //   min-width: 80px !important;
      // }

      // &:nth-child(25) {
      //   max-width: 140px;
      //   min-width: 110px !important;
      // }

      // &:nth-child(26) {
      //   max-width: 130px;
      //   min-width: 100px !important;
      // }

      // &:nth-child(27) {
      //   max-width: 150px;
      //   min-width: 120px !important;
      // }



      .resizer {
        top: 5px;
        right: 10px;
        width: 4px;
        height: 70%;
        position: absolute;
        touch-action: none;

        @include themify($themes) {
          background: themed('colorBorder');
        }

        &.isResizing {

          @include themify($themes) {
            background: themed('colorHeaderDataPicker');
          }
        }
      }
    }
  }

  .resizable-tableVehiclePageExpanded {

    thead {
      min-width: 100px;
      overflow-y: auto;
      overflow-x: hidden;

      &:first-child {
        min-width: 45px;

      }
    }

    tbody {
      overflow-y: scroll;
      overflow-x: hidden;
    }

    tr {
      border-bottom: 4px solid rgba(16, 16, 16, .30);
      padding-left: 20px;
      white-space: nowrap;
      // background-image: linear-gradient((97deg, #0A0A0A 10%, #1B1B1B 99%));
      background-color: transparent;
      border-radius: 4px;
    }

    th,
    td {
      min-width: 200px !important;

      &:first-child {
        max-width: 120px;
        min-width: 70px !important;
      }

      // &:nth-child(2) {
      //   max-width: 140px;
      //   min-width: 70px !important;
      // }

      // &:nth-child(3) {
      //   max-width: 200px;
      //   min-width: 130px !important;
      // }

      // &:nth-child(4) {
      //   max-width: 220px;
      //   min-width: 190px !important;
      // }

      // &:nth-child(5) {
      //   max-width: 110px;
      //   min-width: 70px !important;
      // }

      // &:nth-child(6) {
      //   max-width: 140px;
      //   min-width: 100px !important;
      // }

      // &:nth-child(7) {
      //   max-width: 160px;
      //   min-width: 120px !important;
      // }

      // &:nth-child(8) {
      //   max-width: 120px;
      //   min-width: 90px !important;
      // }

      // &:nth-child(9) {
      //   max-width: 110px;
      //   min-width: 90px !important;
      // }

      // &:nth-child(10) {
      //   max-width: 120px;
      //   min-width: 90px !important;
      // }

      // &:nth-child(11) {
      //   max-width: 140px;
      //   min-width: 100px !important;
      // }

      // &:nth-child(12) {
      //   max-width: 130px;
      //   min-width: 90px !important;
      // }

      // &:nth-child(13) {
      //   max-width: 150px;
      //   min-width: 120px !important;
      // }

      // &:nth-child(14) {
      //   max-width: 150px;
      //   min-width: 120px !important;
      // }

      // &:nth-child(15) {
      //   max-width: 150px;
      //   min-width: 120px !important;
      // }

      // &:nth-child(16) {
      //   max-width: 200px;
      //   min-width: 150px !important;
      // }

      // &:nth-child(17) {
      //   max-width: 170px;
      //   min-width: 120px !important;
      // }

      // &:nth-child(18) {
      //   max-width: 170px;
      //   min-width: 120px !important;
      // }

      // &:nth-child(20) {
      //   max-width: 200px;
      //   min-width: 170px !important;
      // }

      // &:nth-child(21) {
      //   max-width: 170px;
      //   min-width: 120px !important;
      // }

      // &:nth-child(22) {
      //   max-width: 150px;
      //   min-width: 100px !important;
      // }

      // &:nth-child(23) {
      //   max-width: 150px;
      //   min-width: 100px !important;
      // }

      // &:nth-child(24) {
      //   max-width: 150px;
      //   min-width: 120px !important;
      // }

      // &:nth-child(25) {
      //   max-width: 130px;
      //   min-width: 100px !important;
      // }

      // &:nth-child(26) {
      //   max-width: 150px;
      //   min-width: 110px !important;
      // }

      // &:nth-child(27) {
      //   max-width: 130px;
      //   min-width: 90px !important;
      // }

      // &:nth-child(28) {
      //   max-width: 140px;
      //   min-width: 90px !important;
      // }

      .resizer {
        top: 5px;
        right: 10px;
        width: 4px;
        height: 70%;
        position: absolute;
        touch-action: none;

        @include themify($themes) {
          background: themed('colorBorder');
        }

        &.isResizing {

          @include themify($themes) {
            background: themed('colorHeaderDataPicker');
          }
        }
      }
    }
  }

  .resizable-tableVehiclePageUndefinedStock {

    thead {
      min-width: 100px;
      overflow-y: auto;
      overflow-x: hidden;

      &:first-child {
        min-width: 45px;

      }
    }

    tbody {
      overflow-y: scroll;
      overflow-x: hidden;
    }

    tr {
      border-bottom: 4px solid rgba(16, 16, 16, .30);
      padding-left: 20px;
      white-space: nowrap;
      // background-image: linear-gradient((97deg, #0A0A0A 10%, #1B1B1B 99%));
      background-color: transparent;
      border-radius: 4px;
    }

    th,
    td {
      min-width: 200px !important;

      &:first-child {
        // max-width: 180px;
        // min-width: 70px !important;
        max-width: 210px;
        min-width: 180px !important;

      }

      // &:nth-child(2) {
      //   // max-width: 280px;
      //   // min-width: 230px !important;
      //   max-width: 210px;
      //   min-width: 180px !important;

      // }

      // &:nth-child(3) {
      //   // max-width: 210px;
      //   // min-width: 180px !important;
      //   max-width: 130px;
      //   min-width: 90px !important;
      // }

      // &:nth-child(4) {
      //   // max-width: 130px;
      //   // min-width: 90px !important;
      //   max-width: 140px;
      //   min-width: 100px !important;
      // }

      // &:nth-child(5) {
      //   // max-width: 140px;
      //   // min-width: 100px !important;
      //   max-width: 140px;
      //   min-width: 90px !important;
      // }

      // &:nth-child(6) {
      //   // max-width: 140px;
      //   // min-width: 90px !important;
      //   max-width: 100px;
      //   min-width: 60px !important;
      // }

      // &:nth-child(7) {
      //   max-width: 100px;
      //   min-width: 60px !important;
      // }

      // &:nth-child(8) {
      //   max-width: 110px;
      //   min-width: 80px !important;
      // }

      // &:nth-child(9) {
      //   max-width: 170px;
      //   min-width: 140px !important;
      // }

      // &:nth-child(10) {
      //   max-width: 170px;
      //   min-width: 140px !important;
      // }

      // &:nth-child(11) {
      //   max-width: 170px;
      //   min-width: 140px !important;
      // }

      // &:nth-child(12) {
      //   max-width: 210px;
      //   min-width: 180px !important;
      // }

      // &:nth-child(13) {
      //   max-width: 120px;
      //   min-width: 80px !important;
      // }

      // &:nth-child(14) {
      //   max-width: 150px;
      //   min-width: 120px !important;
      // }

      // &:nth-child(16) {
      //   max-width: 200px;
      //   min-width: 150px !important;
      // }

      // &:nth-child(17) {
      //   max-width: 170px;
      //   min-width: 120px !important;
      // }

      // &:nth-child(20) {
      //   max-width: 170px;
      //   min-width: 120px !important;
      // }

      // &:nth-child(21) {
      //   max-width: 170px;
      //   min-width: 120px !important;
      // }

      // &:nth-child(22) {
      //   max-width: 150px;
      //   min-width: 100px !important;
      // }

      // &:nth-child(23) {
      //   max-width: 150px;
      //   min-width: 100px !important;
      // }

      // &:nth-child(24) {
      //   max-width: 130px;
      //   min-width: 80px !important;
      // }

      // &:nth-child(25) {
      //   max-width: 140px;
      //   min-width: 110px !important;
      // }

      // &:nth-child(26) {
      //   max-width: 130px;
      //   min-width: 100px !important;
      // }

      // &:nth-child(27) {
      //   max-width: 150px;
      //   min-width: 120px !important;
      // }

      .resizer {
        top: 5px;
        right: 10px;
        width: 4px;
        height: 70%;
        position: absolute;
        touch-action: none;

        @include themify($themes) {
          background: themed('colorBorder');
        }

        &.isResizing {

          @include themify($themes) {
            background: themed('colorHeaderDataPicker');
          }
        }
      }
    }
  }

  .resizable-tableVehiclePageSoldCars {

    thead {
      min-width: 100px;
      overflow-y: auto;
      overflow-x: hidden;

      &:first-child {
        min-width: 45px;

      }
    }

    tbody {
      overflow-y: scroll;
      overflow-x: hidden;
    }

    tr {
      border-bottom: 4px solid rgba(16, 16, 16, .30);
      padding-left: 20px;
      white-space: nowrap;
      // background-image: linear-gradient((97deg, #0A0A0A 10%, #1B1B1B 99%));
      background-color: transparent;
      border-radius: 4px;
    }

    th,
    td {
      min-width: 200px !important;

      &:first-child {
        max-width: 200px;
        min-width: 170px !important;

      }

      &:nth-child(2) {
        max-width: 280px;
        min-width: 230px !important;
      }

      // &:nth-child(3) {
      //   max-width: 250px;
      //   min-width: 220px !important;
      // }

      // &:nth-child(4) {
      //   max-width: 130px;
      //   min-width: 90px !important;
      // }

      // &:nth-child(5) {
      //   max-width: 140px;
      //   min-width: 100px !important;
      // }

      // &:nth-child(6) {
      //   max-width: 140px;
      //   min-width: 90px !important;
      // }

      // &:nth-child(7) {
      //   max-width: 100px;
      //   min-width: 60px !important;
      // }

      // &:nth-child(8) {
      //   max-width: 100px;
      //   min-width: 60px !important;
      // }

      // &:nth-child(9) {
      //   max-width: 100px;
      //   min-width: 60px !important;
      // }

      // &:nth-child(10) {
      //   max-width: 120px;
      //   min-width: 80px !important;
      // }

      // &:nth-child(11) {
      //   max-width: 120px;
      //   min-width: 80px !important;
      // }

      // &:nth-child(12) {
      //   max-width: 130px;
      //   min-width: 90px !important;
      // }

      // &:nth-child(13) {
      //   max-width: 150px;
      //   min-width: 120px !important;
      // }

      // &:nth-child(14) {
      //   max-width: 150px;
      //   min-width: 120px !important;
      // }

      // &:nth-child(16) {
      //   max-width: 200px;
      //   min-width: 150px !important;
      // }

      // &:nth-child(17) {
      //   max-width: 170px;
      //   min-width: 120px !important;
      // }

      // &:nth-child(20) {
      //   max-width: 170px;
      //   min-width: 120px !important;
      // }

      // &:nth-child(21) {
      //   max-width: 170px;
      //   min-width: 120px !important;
      // }

      // &:nth-child(22) {
      //   max-width: 150px;
      //   min-width: 100px !important;
      // }

      // &:nth-child(23) {
      //   max-width: 150px;
      //   min-width: 100px !important;
      // }

      // &:nth-child(24) {
      //   max-width: 130px;
      //   min-width: 80px !important;
      // }

      // &:nth-child(25) {
      //   max-width: 140px;
      //   min-width: 110px !important;
      // }

      // &:nth-child(26) {
      //   max-width: 130px;
      //   min-width: 100px !important;
      // }

      // &:nth-child(27) {
      //   max-width: 150px;
      //   min-width: 120px !important;
      // }

      .resizer {
        top: 5px;
        right: 10px;
        width: 4px;
        height: 70%;
        position: absolute;
        touch-action: none;

        @include themify($themes) {
          background: themed('colorBorder');
        }

        &.isResizing {

          @include themify($themes) {
            background: themed('colorHeaderDataPicker');
          }
        }
      }
    }
  }

  .resizable-tableConnectivityPage {

    thead {
      min-width: 45px;
      overflow-y: auto;
      overflow-x: hidden;

      &:first-child {
        min-width: 45px;

      }
    }

    tbody {
      overflow-y: scroll;
      overflow-x: hidden;
    }

    tr {
      border-bottom: 4px solid rgba(16, 16, 16, .30);
      // padding-left: 10px;
      margin-left: -31px;
      white-space: nowrap;
      // background-image: linear-gradient((97deg, #0A0A0A 10%, #1B1B1B 99%));
      background-color: transparent;
      border-radius: 4px;
    }

    th,
    td {
      min-width: 45px !important;

      &:first-child {
        max-width: 450px;
        min-width: 140px !important;
      }


      &:nth-child(2) {
        max-width: 450px;
        min-width: 145px !important;
      }

      &:nth-child(3) {
        max-width: 450px;
        min-width: 180px !important;
      }

      &:nth-child(4) {
        max-width: 450px;
        min-width: 200px !important;
      }

      &:nth-child(5) {
        max-width: 450px;
        min-width: 160px !important;
      }

      &:nth-child(6) {
        max-width: 450px;
        min-width: 200px !important;
      }

      &:nth-child(7) {
        max-width: 450px;
        min-width: 80px !important;
      }

      &:nth-child(8) {
        max-width: 450px;
        min-width: 210px !important;
      }

      &:nth-child(9) {
        max-width: 450px;
        min-width: 130px !important;
      }
      &:nth-child(10) {
        max-width: 450px;
        min-width: 140px !important;
      }

      // thead {
      //   min-width: 100px;
      //   overflow-y: auto;
      //   overflow-x: hidden;

      //   &:first-child {
      //     min-width: 45px;
      //   }
      // }

      // // tbody {
      // //   overflow-y: scroll;
      // //   overflow-x: hidden;
      // // }

      // // th,
      // // td {
      // //   min-width: 100px !important;

      // //   &:first-child {
      // //     max-width: 60px;
      // //     min-width: 60px !important;
      // //   }

      //   .resizer {
      //     top: 5px;
      //     right: 10px;
      //     width: 4px;
      //     height: 70%;
      //     position: absolute;
      //     touch-action :none;
      //     background: ${colorBorder};

      //     &.isResizing {
      //       background: ${colorHeaderDataPicker};
      //     }
      //   }
      // }


      .resizer {
        top: 5px;
        right: 10px;
        width: 4px;
        height: 70%;
        position: absolute;
        touch-action: none;

        @include themify($themes) {
          background: themed('colorBorder');
        }

        &.isResizing {

          @include themify($themes) {
            background: themed('colorHeaderDataPicker');
          }
        }
      }
    }
  }

  .resizable-MyOpenCases {
    thead {
      min-width: 100px;
      overflow-y: auto;
      overflow-x: hidden;

      &:first-child {
        min-width: 45px;

      }
    }

    tbody {
      overflow-y: scroll;
      overflow-x: hidden;
    }

    tr {
      border-bottom: 4px solid rgba(16, 16, 16, .30);
      margin-left: -31px;
      white-space: nowrap;
      background-color: transparent;
      border-radius: 4px;
    }

    th,
    td {
      min-width: 150px !important;

      &:first-child {
        max-width: 450px;
        min-width: 160px !important;
      }

      &:nth-child(2) {
        max-width: 450px;
        min-width: 160px !important;
      }

      &:nth-child(3) {
        max-width: 450px;
        min-width: 210px !important;
      }

      &:nth-child(4) {
        max-width: 450px;
        min-width: 180px !important;
      }

      &:nth-child(5) {
        max-width: 450px;
        min-width: 200px !important;
      }

      &:nth-child(6) {
        max-width: 450px;
        min-width: 120px !important;
      }

      &:nth-child(7) {
        max-width: 450px;
        min-width: 210px !important;
      }

      &:nth-child(8) {
        max-width: 450px;
        min-width: 120px !important;
      }

      // &:nth-child(9) {
      //   max-width: 450px;
      //   min-width: 140px !important;
      // }
      // &:nth-child(10) {
      //   max-width: 190px;
      //   min-width: 140px !important;
      // }

      // &:nth-child(11) {
      //   max-width: 180px;
      //   min-width: 120px !important;
      // }

      // thead {
      //   min-width: 100px;
      //   overflow-y: auto;
      //   overflow-x: hidden;

      //   &:first-child {
      //     min-width: 45px;
      //   }
      // }

      // // tbody {
      // //   overflow-y: scroll;
      // //   overflow-x: hidden;
      // // }

      // // th,
      // // td {
      // //   min-width: 100px !important;

      // //   &:first-child {
      // //     max-width: 60px;
      // //     min-width: 60px !important;
      // //   }

      //   .resizer {
      //     top: 5px;
      //     right: 10px;
      //     width: 4px;
      //     height: 70%;
      //     position: absolute;
      //     touch-action :none;
      //     background: ${colorBorder};

      //     &.isResizing {
      //       background: ${colorHeaderDataPicker};
      //     }
      //   }
      // }


      .resizer {
        top: 5px;
        right: 10px;
        width: 4px;
        height: 70%;
        position: absolute;
        touch-action: none;

        @include themify($themes) {
          background: themed('colorBorder');
        }

        &.isResizing {

          @include themify($themes) {
            background: themed('colorHeaderDataPicker');
          }
        }
      }
    }
  }

  .resizable-UnassignedCases {

    thead {
      min-width: 100px;
      overflow-y: auto;
      overflow-x: hidden;

      &:first-child {
        min-width: 45px;

      }
    }

    tbody {
      overflow-y: scroll;
      overflow-x: hidden;
    }

    tr {
      border-bottom: 4px solid rgba(16, 16, 16, .30);
      // padding-left: 20px;
      margin-left: -31px;
      white-space: nowrap;
      // background-image: linear-gradient((97deg, #0A0A0A 10%, #1B1B1B 99%));
      background-color: transparent;
      border-radius: 4px;
    }

    th,
    td {
      min-width: 150px !important;

      // &:first-child {
      //   max-width: 190px;
      //   min-width: 140px !important;
      // }


      &:nth-child(2) {
        max-width: 450px;
        min-width: 230px !important;
      }

      &:nth-child(3) {
        max-width: 450px;
        min-width: 180px !important;
      }

      &:nth-child(4) {
        max-width: 450px;
        min-width: 220px !important;
      }

      &:nth-child(5) {
        max-width: 450px;
        min-width: 100px !important;
      }

      &:nth-child(6) {
        max-width: 450px;
        min-width: 220px !important;
      }

      &:nth-child(7) {
        max-width: 450px;
        min-width: 150px !important;
      }

      &:nth-child(8) {
        max-width: 450px;
        min-width: 120px !important;
      }

      // &:nth-child(9) {
      //   max-width: 190px;
      //   min-width: 140px !important;
      // }
      // &:nth-child(10) {
      //   max-width: 190px;
      //   min-width: 140px !important;
      // }

      // &:nth-child(11) {
      //   max-width: 180px;
      //   min-width: 120px !important;
      // }

      // thead {
      //   min-width: 100px;
      //   overflow-y: auto;
      //   overflow-x: hidden;

      //   &:first-child {
      //     min-width: 45px;
      //   }
      // }

      // // tbody {
      // //   overflow-y: scroll;
      // //   overflow-x: hidden;
      // // }

      // // th,
      // // td {
      // //   min-width: 100px !important;

      // //   &:first-child {
      // //     max-width: 60px;
      // //     min-width: 60px !important;
      // //   }

      //   .resizer {
      //     top: 5px;
      //     right: 10px;
      //     width: 4px;
      //     height: 70%;
      //     position: absolute;
      //     touch-action :none;
      //     background: ${colorBorder};

      //     &.isResizing {
      //       background: ${colorHeaderDataPicker};
      //     }
      //   }
      // }


      .resizer {
        top: 5px;
        right: 10px;
        width: 4px;
        height: 70%;
        position: absolute;
        touch-action: none;

        @include themify($themes) {
          background: themed('colorBorder');
        }

        &.isResizing {

          @include themify($themes) {
            background: themed('colorHeaderDataPicker');
          }
        }
      }
    }
  }

  .resizable-AssignedCases {

    thead {
      min-width: 100px;
      overflow-y: auto;
      overflow-x: hidden;

      &:first-child {
        min-width: 45px;

      }
    }

    tbody {
      overflow-y: scroll;
      overflow-x: hidden;
    }

    tr {
      border-bottom: 4px solid rgba(16, 16, 16, .30);
      // padding-left: 20px;
      margin-left: -31px;
      white-space: nowrap;
      // background-image: linear-gradient((97deg, #0A0A0A 10%, #1B1B1B 99%));
      background-color: transparent;
      border-radius: 4px;
    }

    th,
    td {
      min-width: 100px !important;

      // &:first-child {
      //   max-width: 190px;
      //   min-width: 140px !important;
      // }


      // &:nth-child(2) {
      //   max-width: 180px;
      //   min-width: 145px !important;
      // }

      &:nth-child(3) {
        max-width: 450px;
        min-width: 180px !important;
      }

      &:nth-child(4) {
        max-width: 450px;
        min-width: 220px !important;
      }

      &:nth-child(5) {
        max-width: 450px;
        min-width: 175px !important;
      }

      &:nth-child(6) {
        max-width: 450px;
        min-width: 200px !important;
      }

      &:nth-child(7) {
        max-width: 450px;
        min-width: 100px !important;
      }

      &:nth-child(8) {
        max-width: 450px;
        min-width: 210px !important;
      }

      &:nth-child(9) {
        max-width: 450px;
        min-width: 100px !important;
      }
      // &:nth-child(10) {
      //   max-width: 190px;
      //   min-width: 140px !important;
      // }

      // &:nth-child(11) {
      //   max-width: 180px;
      //   min-width: 120px !important;
      // }

      // thead {
      //   min-width: 100px;
      //   overflow-y: auto;
      //   overflow-x: hidden;

      //   &:first-child {
      //     min-width: 45px;
      //   }
      // }

      // // tbody {
      // //   overflow-y: scroll;
      // //   overflow-x: hidden;
      // // }

      // // th,
      // // td {
      // //   min-width: 100px !important;

      // //   &:first-child {
      // //     max-width: 60px;
      // //     min-width: 60px !important;
      // //   }

      .resizer {
        top: 5px;
        right: 10px;
        width: 4px;
        height: 70%;
        position: absolute;
        touch-action: none;

        @include themify($themes) {
          background: themed('colorBorder');
        }

        &.isResizing {

          @include themify($themes) {
            background: themed('colorHeaderDataPicker');
          }
        }
      }
    }
  }

  .resizable-tableRvTable {

    thead {
      min-width: 100px;
      overflow-y: auto;
      overflow-x: hidden;

      &:first-child {
        min-width: 45px;
      }
    }

    tbody {
      overflow-y: scroll;
      overflow-x: hidden;
    }

    th,
    td {
      z-index: 0;
      min-width: 20px !important;

      &:first-child {
        max-width: 450px;
        min-width: 45px;
      }


      // &:nth-child(3) {
      //   max-width: 150px;
      //   min-width: 50px !important;
      // }

      // &:nth-child(2) {
      //   max-width: 180px;
      //   min-width: 140px !important;
      // }

      // &:nth-child(3) {
      //   max-width: 150px;
      //   min-width: 80px !important;
      // }

      &:nth-child(5) {
        max-width: 330px;
        min-width: 270px !important;
      }

      &:nth-child(6) {
        max-width: 300px;
        min-width: 270px !important;
      }

      &:nth-child(7) {
        max-width: 250px;
        min-width: 165px !important;
      }

      &:nth-child(9) {
        max-width: 140px;
        min-width: 70px !important;
      }

      &:nth-child(10) {
        max-width: 150px;
        min-width: 70px !important;
      }

      &:nth-child(11) {
        max-width: 120px;
        min-width: 70px !important;
      }

      &:nth-child(12) {
        max-width: 120px;
        min-width: 70px !important;
      }

      &:nth-child(13) {
        max-width: 140px;
        min-width: 70px !important;
      }

      &:nth-child(14) {
        max-width: 140px;
        min-width: 50px !important;
      }

      &:nth-child(15) {
        max-width: 120px;
        min-width: 50px !important;
      }

      &:nth-child(16) {
        max-width: 120px;
        min-width: 50px !important;
      }



      .resizer {
        top: 5px;
        right: 10px;
        width: 4px;
        height: 70%;
        position: absolute;
        touch-action: none;

        @include themify($themes) {
          background: themed('colorBorder');
        }

        &.isResizing {

          @include themify($themes) {
            background: themed('colorHeaderDataPicker');
          }
        }
      }
    }
  }


  .react-dnd--off {
    pointer-events: none;
  }

  .react-dnd-draggable {
    z-index: 98 !important;

    td {
      width: inherit;

      &:first-child {
        width: 70px;
        min-width: 70px !important;
      }
    }
  }

  .editable-table {
    user-select: none;
    /* standard syntax */
    -webkit-user-select: none;
    /* webkit (safari, chrome) browsers */
    -moz-user-select: none;
    /* mozilla browsers */
    -ms-user-select: none;
    /* IE10+ */

    thead {
      min-width: 100px;
      overflow-y: auto;
      overflow-x: hidden;


      &:first-child {
        min-width: 45px;
      }
    }

    thead tr {
      position: sticky;
      top: 0;
      background: rgba(19, 19, 19, 1.90);
      z-index: 300;

    }

    th,
    td {
      min-width: 100px;

      &:first-child {
        max-width: 450px;
        min-width: 45px;
      }

      &:nth-child(5) {
        max-width: 330px;
        min-width: 270px !important;
      }

      &:nth-child(6) {
        max-width: 300px;
        min-width: 270px !important;
      }

      &:nth-child(7) {
        max-width: 250px;
        min-width: 165px !important;
      }

      &:nth-child(9) {
        max-width: 140px;
        min-width: 70px !important;
      }

      &:nth-child(10) {
        max-width: 150px;
        min-width: 70px !important;
      }

      &:nth-child(11) {
        max-width: 120px;
        min-width: 70px !important;
      }

      &:nth-child(12) {
        max-width: 120px;
        min-width: 70px !important;
      }

      &:nth-child(13) {
        max-width: 140px;
        min-width: 70px !important;
      }

      &:nth-child(14) {
        max-width: 140px;
        min-width: 50px !important;
      }

      &:nth-child(15) {
        max-width: 120px;
        min-width: 50px !important;
      }

      &:nth-child(16) {
        max-width: 120px;
        min-width: 50px !important;
      }
    }

    .table__editable-table--cell-active {
      max-width: inherit;
      border: none;
      outline: 2px solid $color-greenlynko;
      margin: -7px 10px -6px 0;
    }

    .table__edit-form {
      max-width: fit-content;
      border: none;
      border-radius: 0;
      background: transparent;
      font-size: 13px;
      font-family: 'Roboto', sans-serif;
      padding-left: 0;

      @include themify($themes) {
        background-color: themed('colorBackground');
        color: themed('colorTextAdditional');
      }

      &:active {
        border: none;
        outline: 1px solid $color-blue;
      }
    }

    .form-control {

      &:focus {
        box-shadow: none;
      }
    }
  }
}

.react-table__wrapper {
  display: flex;
  flex-wrap: wrap;
  align-items: baseline;
  justify-content: space-between;
}

.react-table__customizer {
  padding: 0 20px 20px 15px;
}

.table__search {
  display: flex;
  align-items: baseline;
  margin-bottom: 15px;
  color: $color-additional;

  .table__search-input {
    max-width: 250px;
    font-size: 12px;
    margin-right: 20px;

    @include themify($themes) {
      color: themed('colorText');
      background: themed('colorBackground');
    }
  }
}

.table__collapse-content {
  width: 275px;
  position: absolute;
  z-index: 101;
  box-shadow: 0 10px 25px 0 rgba(33, 36, 50, 0.13);

  @include directify($directions) {
    #{directed('right')}: 20px;
  }

  @include themify($themes) {
    background: themed('colorBackground');
  }
}

.react-table__highlighter {

  mark {
    background-color: $color-accent;
    padding: 0;
  }
}

.ReactTable.-highlight .rt-tbody .rt-tr:not(.-padRow):hover {
  background: rgba(0, 0, 0, 0.05)
}

.table__collapse-title-wrap {
  padding: 25px 20px 10px 20px;

  p {
    color: $color-additional;
  }

  @include themify($themes) {
    border-bottom: 1px solid themed('colorBorder');
  }
}

.table__collapse-item {
  padding: 10px 20px 25px 20px;

  @include themify($themes) {
    border-bottom: 1px solid themed('colorBorder');
  }
}

.table__collapse-item--tooltip .tooltip {
  z-index: 101;
}

.table__btn {
  font-size: 18px;
  height: 100%;
  padding: 10px;
  cursor: pointer;
  position: relative;
  display: flex;
  border: none;
  background: transparent;
  transition: all 0.3s;

  h5 {
    color: $color-additional;
    margin-right: 10px;
  }

  svg {
    margin: auto;
    height: 18px;
    width: 18px;

    @include themify($themes) {
      fill: themed('colorIcon');
    }
  }

  @include themify($themes) {
    color: themed('colorText');
  }

  @media screen and (max-width: 640px) {
    padding: 0 5px;
  }

  @keyframes beforePulse {

    from {
      width: 7px;
      height: 7px;
    }

    25% {
      width: 13px;
      height: 13px;
    }

    to {
      width: 7px;
      height: 7px;
    }
  }
}

.table__collapse-back {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 100;
  background: transparent;
  border: none;
}

.table__icon {
  margin: auto 0;
}

.table__toggle {
  margin-left: 0 !important;

  &--disabled {
    opacity: 0.4;
  }
}


.table-fetch-spinner {
  color: $color-accent;
}







///////////////////////tab style/////////////////////////////


.nav {
  @include directify($directions) {
    #{directed('padding-left')}: 10px;
  }
}

.tabs {
  // overflow: auto;

  // .tabs__wrap {
  //   @include directify($directions) {
  //     text-align: directed('left');
  //   }
  //   min-width: min-content;
  //   //-webkit-min-width: min-content;
  // }

  .nav-tabs {

    li {

      .nav-link {
        padding: 10px 20px;

        @include directify($directions) {
          #{directed('margin-right')}: 0px;
        }

        transition: background-color 0.3s;
        cursor: pointer;

        @include themify($themes) {
          color: themed('colorText');
        }

        &:hover,
        &:focus,
        &:active {
          outline: none;
          border: none;


          @include themify($themes) {
            background-color: themed('colorHover');
            border-color: themed('colorHover');
            border-bottom-color: $color-dusty-white;
          }
        }
      }

      .nav-link.active,
      .nav-link.active:focus,
      .nav-link.active:hover {
        background-color: transparent;
        border: none;

        @include themify($themes) {
          // border-color: $color-dusty-white;
          // border-bottom-color: themed('colorBackground');
          // color: themed('colorText');
        }
      }

      &.disabled .nav-link {
        color: $color-additional;
      }
    }
  }

  .tab-pane {
    padding-top: 20px;
  }

  &.tabs--bordered-top {

    .nav-tabs {
      li .nav-link {
        border-top: 2px solid transparent;
        border-radius: 0;
      }

      li .nav-link.active {

        &,
        &:focus,
        &:hover {
          border-radius: 0;
          border-top: 20px solid red;
        }
      }
    }
  }

  &.tabs--bordered-bottom {

    .nav-tabs {

      li .nav-link {
        border-color: #1EF1C6;
        border-bottom: 1px solid #1EF1C6;
        ;
        padding-bottom: 11px;
      }

      li .nav-link.active {

        &,
        &:focus,
        &:hover {
          border-radius: 0;
          border-color: #1EF1C6;
          border-bottom: 1px solid #1EF1C6;
          ;
          border-bottom-color: red;
          padding-bottom: 10px;
        }
      }
    }
  }

  &.tabs--justify {

    .nav-tabs {
      display: flex;
      flex-wrap: nowrap;

      li {
        width: 100%;

        a {
          text-align: center;
        }
      }
    }
  }

  &.tabs--vertical {
    overflow-y: auto;

    .tabs__wrap {
      display: flex;
    }

    .nav-tabs {
      border-bottom: none;
      width: 90px;
      height: 100%;
      flex-wrap: wrap;

      .nav-item {
        margin-bottom: 0;
      }

      li {
        margin-top: 0;
        width: 100%;

        .nav-link {
          @include directify($directions) {
            #{directed('padding-right')}: 10px;
            #{directed('padding-left')}: 0px;
            #{directed('margin-right')}: 0px;
            #{directed('border-right')}: 2px solid #dddddd;
          }

          padding-top: 10px;
          padding-bottom: 10px;
          transition: background-color 0.3s;
          border-radius: 0;
          border: none;

          @include themify($themes) {
            color: themed('colorText');
          }

          &:hover,
          &:focus,
          &:active {
            outline: none;

            @include themify($themes) {
              background-color: themed('colorHover');
            }
          }
        }

        .nav-link.active,
        .nav-link.active:focus,
        .nav-link.active:hover {
          background-color: transparent;

          @include directify($directions) {
            #{directed('border-right-color')}: $color-accent;
          }
        }

        &.disabled .nav-link {
          color: $color-additional;
        }
      }
    }

    .tab-pane {
      padding-top: 0;

      @include directify($directions) {
        #{directed('padding-left')}: 20px;
      }
    }

    &.tabs--vertical-colored {

      .nav-tabs {
        li {

          .nav-link {
            @include directify($directions) {
              #{directed('border-right-color')}: #eeeeee;
            }

            @include directify($directions) {
              #{directed('padding-left')}: 10px;
            }

            text-align: center;
          }

          .nav-link.active,
          .nav-link.active:focus,
          .nav-link.active:hover {
            background-color: #eeeeee;

            @include directify($directions) {
              #{directed('border-right-color')}: #eeeeee;
            }

            color: $color-text;
          }
        }
      }
    }
  }
}

// .tabs--bordered-bottom {

//   .nav-tabs {

//     li .nav-link {
//       border-color: #1EF1C6;
//       // border-top: 10px solid transparent;
//       padding-bottom: 11px;
//     }

//     li .nav-link.active {

//       &, &:focus, &:hover {
//         border-radius: 0;
//         border-color: #1EF1C6;
//         border-top: 10px solid #1EF1C6;
//         // border-top: 20px solid #1EF1C6;
//         padding-bottom: 10px;
//       }
//     }
//   }
// }